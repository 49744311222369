export const SET_MODAL = 'SET_MODAL'
export const PAYMENT_CREATE_INTENT = 'PAYMENT_CREATE_INTENT'
export const PAYMENT_CREATE_INTENT_DATA = 'PAYMENT_CREATE_INTENT_DATA'
export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS'
export const PAYMENT_SUCCESS_DATA = 'PAYMENT_SUCCESS_DATA'

export const PURCHASED_PREMIER_LIST = 'PURCHASED_PREMIER_LIST'
export const PURCHASED_PREMIER_LIST_SUCCESS = 'PURCHASED_PREMIER_LIST_SUCCESS'

export const PREMIER_USER_SHOPPING_CART_RESET =
  'PREMIER_USER_SHOPPING_CART_RESET'
