import _ from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Col, Row } from 'reactstrap'

const PremiumMembership = () => {
  const { user } = useSelector((state) => state.Login)
  const userData = useSelector((state) => state.Login)

  return (
    <div className='premier-unlock mb-3'>
      <Row className='d-flex justify-content-center'>
        <Col
          md='8'
          className='border border-info text-dark rounded-2 py-2 px-2 text-center view-box'
        >
          <Row>
            <Col
              lg='9'
              md='9'
              sm='9'
              xs='12'
              className=' d-flex align-items-center justify-content-center'
            >
              <h4 className='mb-0'>
                <strong className='text-danger'>
                  Unlock exclusive access! Join our premier membership today!
                </strong>
              </h4>
            </Col>
            <Col lg='3' md='3' sm='3' sx='3'>
              {_.size(userData.planList) &&
              !_.every(userData.planList, ['plan_expired', 0]) ? (
                <Link
                  className='btn btn-sm btn-info'
                  // to='/premier-membership-renew/checkout'
                  to='/'
                  title='Renew Premier Plan'
                >
                  RENEW PREMIER PLAN
                </Link>
              ) : (
                <Link
                  className='btn btn-sm btn-info'
                  to={
                    user?.id
                      ? '/premier-membership-auth'
                      : '/premier-membership'
                  }
                  title='Find Out More'
                >
                  FIND OUT MORE
                </Link>
              )}

              {/* <Button color='info' size='sm'>
                JOIN NOW
              </Button> */}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default PremiumMembership
