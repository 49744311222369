import _ from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import LoadingOverlay from 'react-loading-overlay'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Card, CardBody, Col, Container, Form, Row, Spinner } from 'reactstrap'
import Swal from 'sweetalert2'
import { del, finalPost, get, patch, post } from '../../helpers/api_helper'
import { logoutUser } from '../../store/auth/login/actions'
import { EditEntryList } from './Components/EditEntryList'
import { TopBox } from './Components/TopBox'
import $ from 'jquery'
function IsAlphaNumeric(e) {
  var val = e.target.value
  if (!val.match(/^[a-zA-Z0-9,*]+$/)) {
    e.preventDefault()
    return false
  } else {
    return true
  }
}

const EasyEntry = () => {
  const history = useHistory()
  const [easyList, setEasyList] = useState([])
  const [exhibitorList, setExhibitorList] = useState([])
  const [breedList, setBreedList] = useState([])
  const [loading, setLoading] = useState(true)
  const [loadingData, setLoadingData] = useState(true)
  const [showToast, setShowToast] = useState(false)
  const [count, setCount] = useState(0)

  ///
  useEffect(() => {
    const apiAll = async () => {
      await getList()
    }

    get(`/api/v1/profile/exhibitor_list`).then((res) => {
      let data = _.chain(res.data).sortBy('last_name').value()
      setExhibitorList(data)
    })
    get(`/api/v1/breed/category/all`).then((res) => {
      setBreedList(res.data)
    })
    apiAll()
  }, [])

  const delayedQuery = useCallback(
    _.debounce((obj, list, index) => {
      let validationFlag = false
      if (!obj.user_id) {
        validationFlag = true
        toast.error('please select Exhibitor')
      } else if (!obj.ear_no) {
        validationFlag = true
        toast.error('Ear No. field is required')
      } else if (!obj.breed_category_id) {
        validationFlag = true
        toast.error('Please select category')
      } else if (!obj.breed_id) {
        validationFlag = true
        toast.error('Please select breed')
      } else if (obj.group_seq && !obj.group_id) {
        validationFlag = true
        toast.error('Please select group')
      } else if (obj.variety_seq && !obj.variety_id) {
        validationFlag = true
        toast.error('Please select variety')
      } else if (!obj.class_id) {
        validationFlag = true
        toast.error('Please select class')
      } else if (!obj.show_type) {
        validationFlag = true
        toast.warning(
          'Note: Selecting a show type is mandatory, if not selected, your entry will not get saved.'
        )
      }

      if (!validationFlag) {
        $('*').addClass('changeCursor')
        updateData(obj, list, index)
      }
    }, 2000),
    []
  )
  const add = useCallback(
    _.debounce((obj, list, index) => {
      addData(obj, list, index)
    }, 2000),
    []
  )
  const updateData = (data, list, index) => {
    let newObj = {}
    // $('*').addClass('changeCursor')
    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        const element = data[key]
        newObj[key] = element ? element : null
      }
    }

    patch(`/api/v1/easy_entry`, newObj)
      .then((res) => {
        toast.success('Updated Successfully', { autoClose: 3000 })
        list[index]['breed_category_id'] = newObj.breed_category_id
        list[index]['breed_id'] = newObj.breed_id
        list[index]['class_id'] = newObj.class_id
        list[index]['ear_no'] = newObj.ear_no
        list[index]['easy_entry_id'] = newObj.easy_entry_id
        list[index]['group_id'] = newObj.group_id
        list[index]['group_seq'] = newObj.group_seq
        list[index]['show_type'] = newObj.show_type
        list[index]['user_id'] = newObj.user_id
        list[index]['variety_id'] = newObj.variety_id
        list[index]['variety_seq'] = newObj.variety_seq
        list[index]['isAdd'] = false
        list[index]['isDisable'] = true
        setEasyList(list)
        $('*').removeClass('changeCursor')
        getList()
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message)
        if (err?.response?.status === 403) {
          logoutUser(history)
        }
      })
  }

  const onUpdate = (e, i, item) => {
    let name = e.target.name
    let value = e.target.value
    const list = [...easyList]

    if (name === 'ear_no') {
      setShowToast(false)
      if (value.length < 50) {
        let IsAlNumeric = IsAlphaNumeric(e)
        if (IsAlNumeric === true || e.target.value === '') {
          list[i]['ear_no'] = value.toUpperCase()
        }
      }
      if (value.length === 49 && !showToast) {
        setShowToast(true)
        setCount(1)
      }
      if (showToast && count === 1) {
        toast.error('Max Ear. No Should be 50 Character')
      }
    }
    if (name === 'user_id') list[i].user_id = value ? Number(value) : ''
    if (name === 'breed_category_id') {
      list[i]['breed_category_id'] = value ? Number(value) : ''
      list[i]['breed_id'] = ''
      list[i]['group_seq'] = ''
      list[i]['variety_seq'] = ''
      list[i]['variety_id'] = ''
      list[i]['class_id'] = ''
      list[i]['group_id'] = ''
    }

    if (name === 'breed_id') {
      let breed = item.find((s) => s.breed_id === Number(value))

      // group_seq
      list[i]['breed_id'] = value ? Number(value) : ''
      list[i]['group_seq'] = breed?.group_seq ? Number(breed?.group_seq) : ''
      list[i]['variety_seq'] = breed?.variety_seq
        ? Number(breed?.variety_seq)
        : ''
      list[i]['variety_id'] = ''

      list[i]['group_id'] = ''
      list[i]['class_id'] = ''
    }
    if (name === 'group_id') {
      list[i]['group_id'] = value ? Number(value) : null
      list[i]['class_id'] = ''
      list[i]['variety_id'] = ''
    }
    if (name === 'variety_id') {
      list[i]['variety_id'] = value ? Number(value) : null
      list[i]['class_id'] = ''
    }
    if (name === 'class_id') {
      list[i]['class_id'] = value ? Number(value) : ''
    }
    if (name === 'show_type') list[i]['show_type'] = value ? value : ''

    setEasyList(list)
    // setTimeout(() => {
    delayedQuery(list[i], list, i)
    // }, 1000)
    // if (list[i].breed_category_id && list[i].breed_id && list[i].class_id) {
    //   if (
    //     (list[i].group_id && list[i].group_seq) ||
    //     (list[i].variety_id && list[i].variety_seq) ||
    //     (!list[i].group_id &&
    //       !list[i].group_seq &&
    //       !list[i].variety_id &&
    //       !list[i].variety_seq)
    //   ) {
    //     if (!list[i].show_type) {
    //       toast.error('Please select show type')
    //     }
    //   }
    // }
    // if (list[i].breed_category_id && list[i].breed_id && list[i].class_id) {
    //   if (
    //     (list[i].group_id && list[i].group_seq) ||
    //     (list[i].variety_id && list[i].variety_seq) ||
    //     (!list[i].group_id &&
    //       !list[i].group_seq &&
    //       !list[i].variety_id &&
    //       !list[i].variety_seq)
    //   ) {
    //     if (list[i].show_type) {

    //     }
    //   }
    // }
  }
  // }

  const addData = (data, list, i) => {
    let newObj = {}
    let validationFlag = false
    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        const element = data[key]
        newObj[key] = element ? element : null
      }
    }
    if (!newObj.ear_no) {
      $('*').removeClass('changeCursor')
      validationFlag = true
      toast.error('Ear no.field is required')
    }
    if (!validationFlag) {
      finalPost(`/api/v1/easy_entry`, newObj)
        .then((res) => {
          $('*').removeClass('changeCursor')
          toast.success('Easy entry has been created successfully')
          getList()
          defaultSave(i)
        })
        .catch((err) => {
          $('*').removeClass('changeCursor')
          toast.error(err?.response?.data)
          // toast.error(err?.response?.data?.message)
          if (err?.response?.status === 403) {
            logoutUser(history)
          }
        })
    }
  }

  const onAdd = (e, i, item) => {
    let { name, value } = e.target

    const list = [...easyList]

    if (name === 'ear_no') {
      let IsAlNumeric = IsAlphaNumeric(e)
      if (IsAlNumeric === true || e.target.value === '') {
        list[i]['ear_no'] = value.toUpperCase()
      }
      if (value.length > 50) {
        toast.error('Max Ear. No Should be 50 Character', { toastId: 1 })
        return
      } else {
        setEasyList(list)
      }
      // return
    }
    if (name === 'user_id') list[i].user_id = value ? Number(value) : null
    if (name === 'breed_category_id') {
      list[i]['breed_category_id'] = value ? Number(value) : null
      list[i]['breed_id'] = ''
      list[i]['group_seq'] = ''
      list[i]['variety_seq'] = ''
      list[i]['variety_id'] = ''
      list[i]['class_id'] = ''
      list[i]['group_id'] = ''
    }

    if (name === 'breed_id') {
      let breed = item.find((s) => s.breed_id === Number(value))

      // group_seq
      list[i]['breed_id'] = value ? Number(value) : null
      list[i]['group_seq'] = breed?.group_seq ? Number(breed?.group_seq) : null
      list[i]['variety_seq'] = breed?.variety_seq
        ? Number(breed?.variety_seq)
        : null
      list[i]['variety_id'] = ''
      list[i]['class_id'] = ''
      list[i]['group_id'] = ''
    }
    if (name === 'group_id') {
      list[i]['group_id'] = value ? Number(value) : null
      list[i]['class_id'] = ''
      list[i]['variety_id'] = ''
    }
    if (name === 'variety_id') {
      list[i]['variety_id'] = value ? Number(value) : null
      list[i]['class_id'] = ''
    }
    if (name === 'class_id') {
      list[i]['class_id'] = value ? Number(value) : null
    }
    if (name === 'show_type') list[i]['show_type'] = value ? value : ''
    // validation for show type
    // add(list[i], list, i)
    if (
      list[i].user_id &&
      list[i].breed_category_id &&
      list[i].breed_id &&
      list[i].class_id
    ) {
      if (
        (list[i].group_id && list[i].group_seq) ||
        (list[i].variety_id && list[i].variety_seq) ||
        (!list[i].group_id &&
          !list[i].group_seq &&
          !list[i].variety_id &&
          !list[i].variety_seq)
      ) {
        if (!list[i].show_type) {
          toast.warning(
            'Note: Selecting a show type is mandatory, if not selected, your entry will not get saved.'
          )
        }
      }
    }
    // validation for pass value in api
    if (
      list[i].user_id &&
      list[i].breed_category_id &&
      list[i].breed_id &&
      list[i].class_id
    ) {
      if (
        (list[i].group_id && list[i].group_seq) ||
        (list[i].variety_id && list[i].variety_seq) ||
        (!list[i].group_id &&
          !list[i].group_seq &&
          !list[i].variety_id &&
          !list[i].variety_seq)
      ) {
        if (list[i].show_type) {
          // addData(list[i], list, i)
          // setTimeout(() => {
          $('*').addClass('changeCursor')
          add(list[i], list, i)
          // }, 1000)
        }
      }
    }

    setEasyList(list)
  }

  const editable = (i) => {
    let list = [...easyList]
    list[i].isDisable = false
    setEasyList(list)
  }

  let handleDelete = (id, i) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      width: '380px',
      showCancelButton: true,
      confirmButtonColor: '#062575',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        let list = [...easyList]

        del(`/api/v1/easy_entry/${id}`)
          .then((res) => {
            list[i]['breed_category_id'] = ''
            list[i]['breed_id'] = ''
            list[i]['class_id'] = ''
            list[i]['ear_no'] = ''
            list[i]['easy_entry_id'] = ''
            list[i]['group_id'] = ''
            list[i]['group_seq'] = ''
            list[i]['show_type'] = ''
            list[i]['user_id'] = ''
            list[i]['variety_id'] = ''
            list[i]['variety_seq'] = ''
            list[i]['isAdd'] = true
            list[i]['isDisable'] = false
            setEasyList(list)
            toast.success(res.data.message)
            getList()
          })
          .catch((err) => {
            toast.error(err?.response?.data?.message)
            if (err?.response?.status === 403) {
              logoutUser(history)
            }
          })
      }
    })
  }

  const getList = async () => {
    setLoading(true)
    try {
      const res = await post(`/api/v1/easy_entry/list`)

      const myPromise = new Promise(async (resolve, reject) => {
        let array = []

        for (let i = 0; i < 25; i++) {
          if (res && res.data && res?.data.length) {
            let breed_category_id = res ? res?.data[i]?.breed_category_id : ''
            let breedData = {}
            if (breed_category_id) {
              let data = await get(
                `/api/v1/breed/all?published=1&breed_category_id=${breed_category_id}`
              )

              let breed_id = res ? res?.data[i]?.breed_id : ''

              breedData = await data.data.find(
                (res) => res.breed_id === breed_id
              )
            }

            array.push({
              breed_category_id: res?.data[i]?.breed_category_id || '',
              breed_id: res?.data[i]?.breed_id || '',
              class_id: res?.data[i]?.class_id || '',
              ear_no: res?.data[i]?.ear_no || '',
              easy_entry_id: res?.data[i]?.easy_entry_id || '',
              group_id: res?.data[i]?.group_id || '',
              group_seq: breedData?.group_seq || '',
              show_type: res?.data[i]?.show_type || '',
              user_id: res?.data[i]?.user_id || '',
              variety_id: res?.data[i]?.variety_id || '',
              variety_seq: breedData?.variety_seq || '',
              isAdd: res.data ? (res?.data[i] ? false : true) : true,
              isDisable: res.data ? (res?.data[i] ? true : false) : false,
            })
          } else {
            array.push({
              breed_category_id: '',
              breed_id: '',
              class_id: '',
              ear_no: '',
              easy_entry_id: '',
              group_id: '',
              group_seq: '',
              show_type: '',
              user_id: '',
              variety_id: '',
              variety_seq: '',
              isAdd: res.data ? (res?.data[i] ? false : true) : true,
              isDisable: res.data ? (res?.data[i] ? true : false) : false,
            })
          }
        }

        resolve(array)
      })
      myPromise.then((res, err) => {
        setEasyList(res)
        setLoading(false)
        setLoadingData(false)
      })
    } catch (err) {
      console.log(err?.response)
      toast.error(err?.response?.data?.message)
      if (err?.response?.status === 403) {
        logoutUser(history)
      }
    }
  }

  const defaultSave = (i) => {
    let list = [...easyList]
    list[i]['breed_category_id'] = ''
    list[i]['breed_id'] = ''
    list[i]['class_id'] = ''
    list[i]['ear_no'] = ''
    list[i]['easy_entry_id'] = ''
    list[i]['group_id'] = ''
    list[i]['group_seq'] = ''
    list[i]['show_type'] = ''
    list[i]['user_id'] = ''
    list[i]['variety_id'] = ''
    list[i]['variety_seq'] = ''
    list[i]['isAdd'] = true
    list[i]['isDisable'] = false

    setEasyList(list)
  }

  return (
    <React.Fragment>
      <div className="page-content bg-gray-101">
        <section id="about" className="section1 bg-gray-101 pages event-list">
          <Container fluid={true} className="auto-fit">
            <Row>
              <Col lg={12} className="text-center">
                <div className="page-title-box justify-content-between">
                  <h2 className="top-title mb-4">
                    <span>Easy Entry</span>
                  </h2>
                </div>
              </Col>
            </Row>
            <TopBox />
          </Container>
          <Container
            fluid={true}
            className="auto-fit easy-select styled-select easy-select"
          >
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <Row>
                      <div className="mb-3 w4 res-d-none"></div>
                      <div className="mb-3 w12">
                        <div className="entry-box">Exhibitor</div>
                      </div>
                      <div className="mb-3 w8">
                        <div className="entry-box">Ear No.</div>
                      </div>
                      <div className="mb-3 w8">
                        <div className="entry-box">Category</div>
                      </div>
                      <div className="mb-3 w14">
                        <div className="entry-box">Breed</div>
                      </div>
                      <div className="mb-3 w14">
                        <div className="entry-box">Group</div>
                      </div>
                      <div className="mb-3 w14">
                        <div className="entry-box">Variety</div>
                      </div>
                      <div className="mb-3 w8">
                        <div className="entry-box">Class</div>
                      </div>
                      <div className="mb-3 w8">
                        <div className="entry-box">Show Type</div>
                      </div>
                      <div className="mb-3 w10"></div>
                    </Row>
                    {!loadingData ? (
                      <LoadingOverlay
                        active={loading}
                        spinner
                        text="Loading your content..."
                      >
                        <Form
                          className="repeater"
                          encType="multipart/form-data"
                        >
                          {easyList &&
                            easyList?.map((item, i) => {
                              return (
                                <EditEntryList
                                  key={i}
                                  item={item}
                                  n={i}
                                  onUpdate={onUpdate}
                                  exhibitorList={exhibitorList}
                                  breedList={breedList}
                                  onAdd={onAdd}
                                  editable={editable}
                                  handleDelete={handleDelete}
                                />
                              )
                            })}
                        </Form>
                      </LoadingOverlay>
                    ) : (
                      <div>
                        <p style={{ fontSize: 24, textAlign: 'center' }}>
                          Loading...
                        </p>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </React.Fragment>
  )
}

export default EasyEntry
