import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

//i18n
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  Button,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Form,
  Input,
} from 'reactstrap'
import logoDark from '../../../assets/images/logo-dark.png'
import logoLight from '../../../assets/images/logo-light.png'
import logoSm from '../../../assets/images/logo.png'
// Redux Store
import {
  changeSidebarType,
  showRightSidebarAction,
  toggleLeftmenu,
} from '../../../store/actions'
import { useLocation } from 'react-router-dom'
const Header = (props) => {
  const { pathname } = useLocation()

  let path = pathname.split('/')[1]

  const [search, setsearch] = useState(false)

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  function tToggle() {
    var body = document.body
    body.classList.toggle('vertical-collpsed')
    body.classList.toggle('sidebar-enable')
  }
  return (
    <React.Fragment>
      <header id='page-topbar'>
        <div className='navbar-header'>
          <div className='d-flex align-items-center'>
            <div className='navbar-brand-box'>
              <Link to='/' className='logo logo-dark'>
                <span className='logo-sm'>
                  <img src={logoSm} alt='' height='30' />
                </span>
                <span className='logo-lg'>
                  <img src={logoDark} alt='' height='20' />
                </span>
              </Link>

              <Link to='/' className='logo logo-light'>
                <span className='logo-sm'>
                  <img src={logoSm} alt='' height='30' />
                </span>
                <span className='logo-lg'>
                  <img src={logoLight} alt='' height='20' />
                </span>
              </Link>
            </div>

            <button
              type='button'
              onClick={() => {
                tToggle()
              }}
              className='btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn'
              id='vertical-menu-btn'
            >
              <i className='fa fa-fw fa-bars' />
            </button>
            <h5 className='text-white mb-0 ms-2'>HERD MANAGEMENT</h5>
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header))
