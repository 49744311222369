import React, { useEffect } from 'react'
import Footer from './Footer'
import Header from './Header'
import { Card, Col, Container, Row } from 'reactstrap'
import classNames from 'classnames'
import { profileMenu } from '../../../common/data/EasyFiedsNav'
import { Link } from 'react-router-dom'
import {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
} from '../../../store/actions'
import { useDispatch, useSelector } from 'react-redux'
import Sidebar from './Sidebar'

const HerdManagementLayout = (props) => {
  const dispatch = useDispatch()
  const { leftSideBarTheme, leftSideBarType } = useSelector((state) => ({
    isPreloader: state.Layout.isPreloader,
    leftSideBarType: state.Layout.leftSideBarType,
    layoutModeType: state.Layout.layoutModeType,
    layoutWidth: state.Layout.layoutWidth,
    topbarTheme: state.Layout.topbarTheme,
    sidebarSizeType: state.Layout.sidebarSizeType,
    showRightSidebar: state.Layout.showRightSidebar,
    leftSideBarTheme: state.Layout.leftSideBarTheme,
  }))
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  useEffect(() => {
    // window.scrollTo(0, 0)
    dispatch(changeLayout('vertical'))
    dispatch(changeSidebarTheme('light'))
  }, [])
  return (
    <React.Fragment>
      <div id='layout-wrapper'>
        <Header />
        <Sidebar
          theme={leftSideBarTheme}
          type={leftSideBarType}
          isMobile={isMobile}
        />
        <div className='main-content'>
          <div className='page-content'>
            <Container fluid>{props.children}</Container>
          </div>
          {/* <Footer /> */}
        </div>
      </div>
    </React.Fragment>
  )
}

export default HerdManagementLayout
