import axios from 'axios'

import store from '../store/index'
//apply base url for axios
const API_URL = process.env.REACT_APP_APIURL

const axiosApi = axios.create({
  baseURL: API_URL,
})

// axiosApi.defaults.headers.common['Authorization'] = token;
// axiosApi.interceptors.response.use(
//   (response) => response,
//   (error) => Promise.reject(error)
// );
axiosApi.interceptors.request.use(function (config) {
  config.headers.Authorization = 'Bearer ' + localStorage.getItem('accessToken')
  return config
})

axiosApi.interceptors.response.use(
  (response) => {
    // store.dispatch({
    //   type: 'LOAD_USER',
    // })
    return response
  },
  async function (error) {
    const originalRequest = error.config

    if (error.response?.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true

      try {
        const refreshToken = localStorage.getItem('refreshToken')
        const response = await axios.post(`${API_URL}/api/v1/token`, {
          token: refreshToken,
        })
        if (response?.data) {
          localStorage.setItem('accessToken', response.data.accessToken)
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        }
      } catch (err) {
        store.dispatch({
          type: 'AUTH_FAIL',
        })

        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')
        localStorage.removeItem('authUser')
      }

      if (error.response?.status === 511) {
        store.dispatch({
          type: 'AUTH_FAIL',
        })
        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')
        localStorage.removeItem('authUser')
      }
      return axiosApi(originalRequest)
    }

    return Promise.reject(error)
  }
)

export async function get(url, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, data, { ...config })
    .then((response) => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response.data)
}
export async function PUT(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response.data)
}

export async function patch(url, data, config = {}) {
  return axiosApi
    .patch(url, { ...data }, { ...config })
    .then((response) => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => response.data)
}

export async function POST(url, data, config = {}) {
  return await axiosApi
    .post(url, data, config)
    .then((response) => response.data)
}

export async function finalPost(url, data, config = {}) {
  return await axiosApi.post(url, data, { ...config })
}

export async function PATCH(url, data, config = {}) {
  return axiosApi
    .patch(url, data, { ...config })
    .then((response) => response.data)
}
