import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
  Spinner,
} from 'reactstrap'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  VendorCategory,
  listingEquipmentFront,
  pageChange,
} from '../../store/actions'
import Pagination from '../Profile/Pagination/Pagination'
import { get } from '../../helpers/api_helper'
import _ from 'lodash'
import { UrlEncodeHook } from '../../hooks'
import ImageModal from '../../components/ImageModal/ImageModal'
import { toast } from 'react-toastify'
const EquipmentListing = () => {
  const { urlEncode } = UrlEncodeHook()
  const dispatch = useDispatch()
  // const { categoryList } = useSelector((state) => state.VendorCategory)
  const { perPage } = useSelector((state) => state.pagination)
  const { EquipmentList, loading } = useSelector((state) => state.Equipment)
  const [modal, setModal] = useState(false)
  const [imageList, setImageList] = useState([])
  const [category_id, setCategory_id] = useState('')
  const [categoryList, setCategoryList] = useState([])
  useEffect(() => {
    // dispatch(VendorCategory())
    get(`/api/v1/easyfied/equipment-product/all-listing`).then((res) => {
      let finalRes = res?.data || []
      if (Array.isArray(finalRes)) {
        finalRes = _.uniqBy(finalRes, 'category_id')
      }
      setCategoryList(finalRes)
    })
  }, [])
  useEffect(() => {
    let params = {
      _limit: perPage,
      _page: 1,
      category_id: category_id,
      _sort: 'equipment_from,created_on',
      _order: 'ASC,desc',
    }
    dispatch(listingEquipmentFront(params))
  }, [perPage, category_id])

  const showModal = async (id) => {
    try {
      const res = await get(
        `/api/v1/easyfied/equipment-product-doc/all?equipment_product_id=${id}&doc_type=img`
      )
      let finalData = res?.data || []
      finalData = _.orderBy(finalData, 'display_order')
      finalData = Array.isArray(finalData)
        ? finalData.map((x) => ({
            original: x.document_file,
            thumbnail: x.document_file,
            description: x.document_title,
          }))
        : []
      setImageList(finalData)
      setModal(true)
    } catch (err) {
      if (err?.response?.data) {
        toast.error(err?.response?.data)
      }
    }
  }

  const modalClose = () => {
    setModal(false)
    setImageList([])
  }

  const paginationClick = (nextPage) => {
    const { selected } = nextPage
    const finalPage = (Number(selected) || 0) + 1
    let params = {
      _limit: perPage,
      _page: finalPage,
      category_id: category_id,
      _sort: 'equipment_from,created_on',
      _order: 'ASC,desc',
    }
    dispatch(listingEquipmentFront(params))
  }
  return (
    <section className='section bg-gray-101 pages event-list mt-12'>
      <Container fluid>
        <div class='row mb-6'>
          <div class='col-md-12 col-lg-12 col-xs-12 text-center'>
            <h2 className='top-title'>
              <span>Equipment & Services</span>
            </h2>
          </div>
        </div>
        <div className='mb-5'>
          <Col lg='12' className='text-center mb-3 filter-line'>
            <p>
              Filters will only show drop down with options that have data
              listed.
            </p>
          </Col>

          <Card>
            <CardHeader>
              <Row className='mt-5 mb-4'>
                <Col md='4' sm='6' xs={'12'} className='mb-4'>
                  <Input
                    id='exampleSelect'
                    className='form-select frm-sel-pad'
                    name='select'
                    type='select'
                    value={category_id}
                    onChange={(e) => {
                      dispatch(pageChange(1))
                      setCategory_id(e.target.value)
                    }}
                  >
                    <option value={''}>Select Category</option>
                    {Array.isArray(categoryList) && categoryList.length
                      ? categoryList.map((category, i) => {
                          return (
                            <option value={category?.category_id || ''} key={i}>
                              {category?.category_name || ''}
                            </option>
                          )
                        })
                      : null}
                  </Input>
                </Col>
                {/* <Col md='3' sm='6' xs={'12'} className='mb-4 text-center'>
                  <Button
                    type='submit'
                    name='btnSubmit'
                    className='w-lg waves-effect waves-light btn btn-success'
                    title='Search'
                  >
                    SEARCH
                  </Button>
                </Col> */}
              </Row>
            </CardHeader>
          </Card>
        </div>

        <Col xl={12} className='tab-structure res-tab-str'>
          {!loading && Array.isArray(EquipmentList) && EquipmentList.length ? (
            EquipmentList.map((equipment, index) => {
              return (
                <div className='mb-5 equi-card' key={index}>
                  <Card>
                    <CardHeader className='res-card-header'>
                      <Row>
                        <Col md='9' sm='9' xs={'12'} className=''>
                          <h4>
                            {equipment?.category_name || ''} -{' '}
                            {equipment?.product_name || ''}
                          </h4>
                        </Col>
                        <Col md='3' sm='3' xs={'12'} className='text-right'>
                          <h4 className='mres'>
                            <span className='high-view text-capitalize'>
                              {equipment?.equipment_product_type || ''}
                            </span>
                          </h4>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <div className='col-md-12 euip-box'>
                        <Row>
                          <Col md='3' sm='6' xs={'12'} className='mb-4'>
                            {' '}
                            <ImageComponent
                              ID={equipment?.equipment_product_id}
                              showModal={showModal}
                            />
                            {/* <img
                              src={'/assets/img/Equipment/empty.jpg'}
                              alt='img'
                            /> */}
                          </Col>
                          <Col
                            md='9'
                            sm='6'
                            xs={'12'}
                            className='mb-4 euip-box'
                          >
                            {equipment?.equipment_from === 'vendor' ? (
                              <p>
                                <h4 className='mb-4'>
                                  {equipment?.business_name || ''}
                                </h4>
                              </p>
                            ) : null}

                            <p>{equipment?.text_description}</p>

                            <p>
                              <h4 className='mb-4'>
                                Price : ${equipment?.amount}
                              </h4>
                            </p>

                            <p className='mb-0'>
                              {/* <Link
                                to={`/easyfides/equipment/equipment_read_more/${urlEncode(
                                  equipment?.equipment_product_id
                                )}`}
                              >
                                <Button
                                  size='md'
                                  color='secondary'
                                  title='Read More'
                                  className='sponsor-read-more me-2 mb-2'
                                >
                                  READ MORE
                                </Button>
                              </Link>
                              <Link to={`/easyfides/equipment/contact/${urlEncode(
                                  equipment?.equipment_product_id
                                )}`}>
                                <Button
                                  size='md'
                                  color='secondary'
                                  title='Send Us An Inquiry'
                                  className='me-2 mb-2' */}
                              {equipment?.equipment_from === 'vendor' ? (
                                <Link
                                  to={`/easyfides/equipment/equipment_read_more/${urlEncode(
                                    equipment?.equipment_product_id
                                  )}`}
                                >
                                  <Button
                                    size='md'
                                    color='secondary'
                                    title='Read More'
                                    className='sponsor-read-more me-2 mb-2'
                                  >
                                    READ MORE
                                  </Button>
                                </Link>
                              ) : (
                                <Link
                                  to={`/easyfides/equipment/contact/${urlEncode(
                                    equipment?.equipment_product_id
                                  )}`}
                                >
                                  <Button
                                    size='md'
                                    color='secondary'
                                    title='Send Us An Inquiry'
                                    className='me-2'
                                  >
                                    SEND US AN INQUIRY
                                  </Button>
                                </Link>
                              )}
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              )
            })
          ) : loading ? (
            <div className='mb-5 equi-card text-center'>
              <Card body>
                <Spinner color='primary' />
              </Card>
            </div>
          ) : (
            <div className='mb-5 equi-card text-center'>
              <Card body>
                <h4> No equipment & services found</h4>
              </Card>
            </div>
          )}
          <ImageModal
            modalClose={modalClose}
            modal={modal}
            imageList={imageList}
          />
          <Row>
            <Col lg={12} className='easy-page'>
              <Pagination paginationClick={paginationClick} />
            </Col>
          </Row>
        </Col>
      </Container>
    </section>
  )
}

export default EquipmentListing

const ImageComponent = ({ ID, showModal }) => {
  const [breedData, setbreedData] = useState({})

  useEffect(() => {
    get(
      `/api/v1/easyfied/equipment-product-doc/all?equipment_product_id=${ID}&doc_type=img`
    ).then((res) => {
      let finalData = res.data
      finalData = _.first(_.orderBy(finalData, 'display_order'))
      setbreedData(finalData)
    })
  }, [ID])

  return breedData?.document_file ? (
    <div onClick={() => showModal(ID)} style={{ cursor: 'pointer' }}>
      <img
        src={breedData?.document_file}
        alt={breedData?.document_title || ''}
        // style={{ width: '100px' }}
        // className='img-thumbnail rounded'
        title={breedData?.document_title || ''}
      />
    </div>
  ) : (
    <img
      src={'/assets/img/logo/logo-hq.png'}
      alt='logo'
      // style={{ width: '100px' }}
      className='img-thumbnail rounded'
      style={{ width: '100%' }}
      title={'logo'}
    />
  )
}
