import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardHeader,
  SelectField,
  Spinner,
  Table,
  TextField,
} from '../Atoms'
import { CardBody, Col, Row } from 'reactstrap'
import Breadcrumb from '../../../common/Breadcrumb'
import Pagination from '../../../HerdManagement/Pagination/Pagination'
import { useDispatch, useSelector } from 'react-redux'
import { pedigreeList, updatePedigreeUpdate } from '../../../store/actions'
import _ from 'lodash'
import moment from 'moment'
import { getDateWithFormat } from '../../../helpers/date_helper'
import { toast } from 'react-toastify'
import { Field, Form, Formik, useFormikContext } from 'formik'
import { nextPage } from '../../../store/HerdManagement/Pagination/action'

const LIMIT = process.env.REACT_APP_LIMIT
// const LIMIT = 5

const SoldAndOther = () => {
  const { PedigreeList, loading, page } = useSelector((state) => {
    return {
      PedigreeList: state.herdManagement.Pedigree.pedigree.pedigreeList,
      loading: state.herdManagement.Pedigree.pedigree.loading,
      page: state?.herdManagement?.pagination?.page || 1,
    }
  })
  const dispatch = useDispatch()
  const [isReloadComponentCall, setIsReloadComponentCall] = useState(false)
  const [reload, setReload] = useState(false)
  const [isDelete, setIsDelete] = useState(false)

  const paginationClick = (p) => {
    const { selected } = p
    const finalPage = (Number(selected) || 0) + 1

    const res = dispatch(nextPage(finalPage))
    if (res) {
      setReload(!reload)
      setIsReloadComponentCall(true)
    }
  }
  const updatePedigreeApi = (body) => {
    return new Promise((resolve, reject) => {
      dispatch(
        updatePedigreeUpdate(body, (err, data) => {
          if (err) {
            reject(err)
          } else {
            resolve(data)
          }
        })
      )
    })
  }
  const onMovePedigree = async (rabbit) => {
    try {
      const updateObj = {
        params: { id: rabbit.pedigree_id },
        data: {
          archieve: 'pedigree',
          rabbit_data: [],
        },
      }
      const res = await updatePedigreeApi(updateObj)
      if (res) {
        toast.success(res.data)
        setReload(!reload)
        setIsDelete(true)
        setIsReloadComponentCall(true)
        // toast.success(res.data)
        // let finalPage = page
        // if (_.size(PedigreeList) === 1) {
        //   finalPage -= 1
        // }
        // const data = {
        //   params: {
        //     _limit: LIMIT,
        //     _page: finalPage,
        //   },
        // }
        // dispatch(pedigreeList(data))
      }
    } catch (err) {
      if (Array.isArray(err)) {
        toast.error(_.get(_.first(err), 'msg'))
      } else if (err.response.data) {
        toast.error(err.response.data)
      } else if (err) {
        toast.error(err)
      }
    }
  }
  return (
    <Card className='layout'>
      <Row>
        <CardHeader>
          <Row>
            <Breadcrumb title='Sold / Other' />
          </Row>
        </CardHeader>
        <CardBody>
          <Formik initialValues={{ archieve: 'sold', search: '' }}>
            {({ values }) => {
              return (
                <Form>
                  <Col md={12}>
                    <SearchComponent />
                    {isReloadComponentCall ? (
                      <ReloadComponent reload={reload} isDelete={isDelete} />
                    ) : null}
                    <Row className='mb-3'>
                      <Col md='6' lg='3' sm='12'>
                        <Field
                          name='archieve'
                          type='select'
                          id='archieve'
                          option={[
                            {
                              label: 'Sold',
                              value: 'sold',
                            },
                            {
                              label: 'Other',
                              value: 'other',
                            },
                          ]}
                          onlyShowOption
                          component={SelectField}
                        />
                      </Col>
                      <Col md='6' lg='3' sm='12'>
                        <Field
                          name='search'
                          placeholder='Search By Breed Name'
                          type='text'
                          id='search'
                          // isSearch
                          // onChange={(e) => {
                          //   // console.log(e)
                          //   formProps.setFieldValue('search', e.target.value)
                          // }}
                          component={TextField}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={12}>
                    <Table className='table-responsive'>
                      <thead>
                        <tr>
                          <th className='w-1'>No.</th>
                          <th>Breed Name</th>
                          <th>Ear/Tag #</th>
                          <th>Animal Name</th>
                          <th>Gender</th>
                          <th>Date of Birth</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!loading && _.size(PedigreeList) ? (
                          PedigreeList.map((pedigree, i) => {
                            const rabbit = _.first(pedigree?.rabbit_data)
                            return (
                              <tr>
                                <td className='w-1'>
                                  {LIMIT * (page - 1) + i + 1}
                                </td>
                                <td>{rabbit?.breed_name || ''}</td>
                                <td>{rabbit?.ear_no || ''}</td>
                                <td>{rabbit?.rabbit_name || ''}</td>
                                <td>
                                  {rabbit?.rabbit_gender === 'M'
                                    ? 'Buck'
                                    : 'Doe'}
                                </td>
                                <td>
                                  {rabbit.date_of_birth
                                    ? moment(
                                        getDateWithFormat(
                                          new Date(
                                            rabbit.date_of_birth
                                          ).toISOString()
                                        )
                                      ).format('MMM Do YYYY')
                                    : '-'}{' '}
                                </td>
                                <td>
                                  <Button
                                    color='warning'
                                    size='sm'
                                    onClick={() => onMovePedigree(pedigree)}
                                    title='Move to Pedigree'
                                  >
                                    MOVE TO PEDIGREE
                                  </Button>
                                </td>
                              </tr>
                            )
                          })
                        ) : loading ? (
                          <tr>
                            <td colSpan={7} className='text-center'>
                              <Spinner size='sm' />
                            </td>
                          </tr>
                        ) : (
                          <tr>
                            <td colSpan={7} className='text-center'>
                              No {values.archieve === 'sold' ? 'sold' : 'other'}{' '}
                              pedigrees found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>

                    <Pagination paginationClick={paginationClick} />
                  </Col>
                </Form>
              )
            }}
          </Formik>
        </CardBody>
      </Row>
    </Card>
  )
}

export default SoldAndOther

export const SearchComponent = () => {
  const dispatch = useDispatch()

  const { values } = useFormikContext()

  useEffect(() => {
    const data = {
      params: {
        _page: 1,
        _limit: LIMIT,
        search: values?.search || '',
      },
      data: {
        archieve: values?.archieve,
      },
    }
    dispatch(pedigreeList(data))
  }, [values?.archieve, values?.search || ''])
  return null
}

export const ReloadComponent = ({ reload, isDelete }) => {
  const dispatch = useDispatch()

  const { values } = useFormikContext()

  const { PedigreeList, page } = useSelector((state) => {
    return {
      PedigreeList: state.herdManagement.Pedigree.pedigree.pedigreeList,
      BreedList: state.herdManagement.Pedigree.configureData?.breed_list || [],
      loading: state.herdManagement.Pedigree.pedigree.loading,
      page: state?.herdManagement?.pagination?.page || 1,
    }
  })

  useEffect(() => {
    let finalPage = page

    if (Array.isArray(PedigreeList) && PedigreeList.length === 1 && isDelete) {
      finalPage -= 1
    }
    const data = {
      params: {
        _page: finalPage,
        _limit: LIMIT,
        search: values?.search || '',
      },
      data: {
        archieve: values?.archieve,
      },
    }
    dispatch(pedigreeList(data))
  }, [reload])
  return null
}
