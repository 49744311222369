import {
  TRADITIONAL_PLAN_PAYMENT_INTENT,
  TRADITIONAL_PLAN_PAYMENT_INTENT_ACTION,
  TRADITIONAL_RENEW_PLAN_PAYMENT_SUCCESS,
} from './actionType'

const initialState = {
  error: '',
  loading: true,
  paymentIntent: {},
  renewPaymentData: {
    message: '',
    success: null,
  },
  renewLoading: true,
}

const TraditionalPaymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case TRADITIONAL_PLAN_PAYMENT_INTENT_ACTION:
      return {
        ...state,
        paymentIntent: {},
        loading: true,
      }
    case TRADITIONAL_PLAN_PAYMENT_INTENT:
      return {
        ...state,
        paymentIntent: action.payload,
        loading: false,
      }
    case TRADITIONAL_RENEW_PLAN_PAYMENT_SUCCESS:
      return {
        ...state,
        renewPaymentData: {
          ...state.renewPaymentMessage,
          message: action.payload.message,
          success: action.payload.success,
        },
        renewLoading: false,
      }

    default:
      return state
  }
}
export default TraditionalPaymentReducer
