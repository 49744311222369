import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { Table } from 'react-super-responsive-table'
import { toast } from 'react-toastify'
import { Col, Container, Modal, Row } from 'reactstrap'
import Swal from 'sweetalert2'
import checkImg from '../../assets/images/users/check.png'
// Image
import deleteImg from '../../assets/images/users/delete.png'
import { get, post, POST } from '../../helpers/api_helper'
import { fixed, shopCartPrice } from '../../helpers/utils'
import { getAllEventCart, getAllShopCartValue } from '../../store/actions'
import getShoppingCart from '../../store/shoppingCart/saga'
import classnames from 'classnames'
import { UrlEncodeHook } from '../../hooks'

const ShoppingCart = (props) => {
  const { hasShoppingCart } = (props.location && props.location.state) || {}
  const { urlEncode } = UrlEncodeHook()

  const dispatch = useDispatch()
  const history = useHistory()

  const [value, setValue] = useState('')
  const [error, setError] = useState('')
  let user = JSON.parse(localStorage.getItem('authUser'))
  // let event = JSON.parse(localStorage.getItem('events'));
  const shoppingCart = useSelector((state) => state.shopping_cart)

  const { shopping_product, eventList, loading } = shoppingCart

  const { cart_data, event_details, final_price } = eventList
  // console.log(
  //   '🚀 ~ file: ShoppingCart.js:35 ~ ShoppingCart ~ event_details:',
  //   event_details
  // )
  const [modal_center, setmodal_center] = useState(false)
  const [isLoading, setisLoading] = useState(false)
  const [eventLoading, setEventLoading] = useState(true)
  const [username, setUsername] = useState(null)

  //
  const deleteProduct = (productData) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      width: '380px',
      showCancelButton: true,
      confirmButtonColor: '#062575',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        let data = {
          user_id: user.id,
          event_id: event_details.event_id,
        }
        const config = {
          headers: {
            'Content-Type': 'application/json',
          },
        }

        let finalData = {
          purchased_product_id: productData.purchased_product_id,
          user_id: productData.user_id,
        }
        POST(`api/v1/additional-product/delete`, finalData, config).then(
          (res) => {
            toast.success(res.data)
            // debugger
            dispatch(getAllShopCartValue(data))

            if (
              cart_data &&
              cart_data.length === 0 &&
              shopping_product &&
              (shopping_product.length === 0 || shopping_product.length === 1)
            ) {
              history.push('/auth-events')
            }
          }
        )
      }
    })
  }

  const deleteAll = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      width: '380px',
      showCancelButton: true,
      confirmButtonColor: '#062575',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        let data = {
          user_id: user.id,
          event_id: event_details.event_id,
        }

        const config = {
          headers: {
            'Content-Type': 'application/json',
          },
        }

        POST(`api/v1/additional-product/delete`, data, config).then((res) => {
          toast.success(res.data)

          let userData = {
            user_id: user.id,
          }

          dispatch(getAllShopCartValue(userData))

          if (
            cart_data &&
            cart_data.length === 0 &&
            shopping_product &&
            (shopping_product.length === 0 || shopping_product.length === 1)
          ) {
            history.push('/auth-events')
          }
        })
      }
    })
  }
  const deleteEvent = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      width: '380px',
      showCancelButton: true,
      confirmButtonColor: '#062575',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        let data = {
          event_id: event_details.event_id,
        }

        const config = {
          headers: {
            'Content-Type': 'application/json',
          },
        }

        POST(`api/v1/participation/delete`, data, config).then((res) => {
          toast.success(res.data)
          dispatch(getAllEventCart(event_details.event_id))
          if (
            cart_data &&
            (cart_data.length === 0 || cart_data.length === 1) &&
            shopping_product &&
            shopping_product.length === 0
          ) {
            history.push('/auth-events')
          }
          // if (cart_data && cart_data.length === 0) {
          //   history.push('/auth-events')
          // }

          // if (shopping_product && shopping_product.length === 0) {
          //   history.push('/auth-events')
          // }
        })
      }
    })
  }

  useEffect(() => {
    get(`/api/v1/profile/profile_details`).then((data) => {
      setUsername(data.data.last_name + ' ' + data.data.first_name)
    })
    const deleteEvent = async () => {
      if (!loading && event_details?.event_id) {
        if (
          event_details.deadlines.length &&
          event_details.deadlines[0].deadline_status === 'expired'
        ) {
          let data1 = {
            user_id: user.id,
            event_id: event_details.event_id,
          }
          const config = {
            headers: {
              'Content-Type': 'application/json',
            },
          }
          const res = await POST(
            `api/v1/additional-product/delete`,
            data1,
            config
          )
          if (res) {
            let userData = {
              user_id: user.id,
            }
            dispatch(getAllShopCartValue(userData))
          }
          let data2 = {
            event_id: event_details.event_id,
          }
          const res2 = await POST(`api/v1/participation/delete`, data2, config)
          if (res2) {
            dispatch(getAllEventCart(user.id))
          }
          if (res || res2) {
            setEventLoading(false)
          }
        }
      } else {
        if (!loading) {
          setEventLoading(false)
        }
      }
    }
    deleteEvent()
  }, [event_details?.event_id, loading])

  //
  useEffect(() => {
    // setEventLoading(true)
    let data = {
      user_id: user.id,
    }
    dispatch(getAllShopCartValue(data))
    if (hasShoppingCart) {
      dispatch(getAllEventCart(user.id))
    }
  }, [])

  let total = 0
  if (shopping_product && shopping_product.length > 0) {
    total = shopCartPrice(shopping_product)
  }

  let showType = event_details?.show_details || []

  const paymentData = () => {
    let validation = false
    let err = ''
    if (!value) {
      err = 'Please accept terms and conditions'
    } else {
      err = ''
    }
    if (err) {
      validation = true
      setError(err)
    } else {
      setError(err)
    }
    if (!validation) {
      setisLoading(true)

      if (event_details.is_online_payment !== 1) {
        post(
          `api/v1/payment/get-total?in_cart=1&event_id=${event_details.event_id}`
        ).then((data) => {
          // setEventLoading(false)
          let grandTotal = total + final_price

          if (data.total_final_price === grandTotal) {
            if (grandTotal > -1) {
              post(
                `api/v1/participation/cart/offline-checkout?event_id=${event_details.event_id}&event_type=${event_details.event_type}`
              ).then((res) => {
                setmodal_center(true)
                setTimeout(() => {
                  setisLoading(false)
                  history.push({ pathname: '/auth-events' })
                }, 4000)
              })
            } else {
              history.push({ pathname: '/auth-events' })
            }
          } else {
            history.push({ pathname: '/auth-events' })
          }
        })
      } else {
        setisLoading(false)
        // setEventLoading(false)
        history.push({
          pathname: `/checkout`,
          search: `?event_id=${urlEncode(event_details.event_id)}&event_type=${
            event_details.event_type
          }`, // query string ,
          state: {
            // location state
            update: true,
          },
        })
      }
    }
  }

  let grandTotal = total + final_price

  let isDisabled = false
  if (
    grandTotal < 5 &&
    ['integrated', 'traditional'].includes(event_details?.plan_type_event)
  ) {
    isDisabled = true
  }

  return (
    <React.Fragment>
      <React.Fragment>
        {/* {!eventLoading ? ( */}
        <div className='page-content bg-gray-101'>
          <section id='about' class='section1 bg-gray-101 pages event-list'>
            <Container fluid={true} className='auto-fit'>
              <Row>
                <Col lg={12} className='text-center'>
                  <div className='page-title-box justify-content-between'>
                    <h2 className='top-title'>
                      <span>SHOPPING CART</span>
                    </h2>
                  </div>
                </Col>
              </Row>
              {cart_data.length || shopping_product.length ? (
                <Row>
                  {isDisabled ? (
                    <Col lg={12} className='mb-3'>
                      {' '}
                      <h5 className='min-order text-bold text-danger'>
                        A minimum order of $5.00 is required to checkout.
                      </h5>{' '}
                    </Col>
                  ) : null}

                  {cart_data.length > 0 ? (
                    <Col lg={12}>
                      <div className='table-responsive text-center mb-5'>
                        <Table className='table mb-0 cust-tab'>
                          <thead className='table-color'>
                            <tr>
                              <th>No.</th>
                              <th className=''>Event Name</th>
                              <th>Units</th>
                              <th style={{ textAlign: 'right' }}>
                                Final Price
                              </th>
                              <th className='w-10'></th>
                            </tr>
                          </thead>
                          <tbody>
                            {!loading && cart_data.length > 0 ? (
                              <tr>
                                <td>1.</td>
                                <td>{event_details?.event_name}</td>
                                <td>
                                  {cart_data.map((events) => {
                                    let show = showType.find(
                                      (ev) => ev.show_id === events.show_id
                                    )
                                    return (
                                      <ul>
                                        <li>
                                          Show {show?.show_char} :{' '}
                                          {events.total_exh} Times{' '}
                                          {events?.total_entry} (animals) <br />
                                        </li>
                                      </ul>
                                    )
                                  })}
                                </td>

                                <td style={{ textAlign: 'right' }}>
                                  ${fixed(final_price, 2, 2)}
                                </td>
                                <td>
                                  {' '}
                                  <img
                                    src={deleteImg}
                                    alt='delete'
                                    className='p-r-3 res-cart-icon'
                                    onClick={deleteEvent}
                                  />
                                </td>
                              </tr>
                            ) : eventList && eventList.length === 0 ? (
                              <tr>
                                <td colSpan={5}>No product found</td>
                              </tr>
                            ) : (
                              <tr>
                                <td colSpan={5}>Loading...</td>
                              </tr>
                            )}

                            <tr className='total-box'>
                              <td colSpan={3}>TOTAL</td>
                              <td style={{ textAlign: 'right' }}>
                                ${fixed(final_price, 2, 2)}
                              </td>
                              <td></td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  ) : (
                    <Col lg={12}>
                      <div className='table-responsive text-center mb-5'>
                        <Table className='table mb-0 cust-tab'>
                          <thead className='table-color'>
                            <tr>
                              <th>{event_details.event_name}</th>
                            </tr>
                          </thead>
                        </Table>
                      </div>
                    </Col>
                  )}
                  {shopping_product && shopping_product.length > 0 && (
                    <Col lg={12}>
                      <div className='table-responsive text-center mb-5'>
                        <Table className='table mb-0 cust-tab'>
                          <thead className='table-color'>
                            <tr>
                              <th>No.</th>
                              <th className='w-60'>Additional Product</th>
                              <th style={{ textAlign: 'right' }}>
                                Final Price
                              </th>
                              <th className='w-10'>
                                {shopping_product && shopping_product.length ? (
                                  <img
                                    src={deleteImg}
                                    alt='delete'
                                    onClick={deleteAll}
                                    className='p-r-3 res-cart-icon'
                                  />
                                ) : null}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {!loading &&
                            shopping_product &&
                            shopping_product.length > 0 ? (
                              shopping_product.map((shopping, i) => {
                                return (
                                  <tr>
                                    <td>{i + 1}</td>
                                    <td>{shopping?.product_name}</td>
                                    <td style={{ textAlign: 'right' }}>
                                      $
                                      {fixed(
                                        shopping.unit_price * shopping.units,
                                        2,
                                        2
                                      )}
                                    </td>
                                    <td>
                                      <img
                                        src={deleteImg}
                                        alt='delete'
                                        className='p-r-3 res-cart-icon'
                                        onClick={() => deleteProduct(shopping)}
                                      />
                                    </td>
                                  </tr>
                                )
                              })
                            ) : shopping_product &&
                              shopping_product.length === 0 ? (
                              <tr>
                                <td colSpan={4}>no product found</td>
                              </tr>
                            ) : (
                              <tr>
                                <td colSpan={4}>Loading...</td>
                              </tr>
                            )}
                            <tr className='total-box'>
                              <td></td>
                              <td colSpan={1}>TOTAL</td>
                              <td style={{ textAlign: 'right' }}>
                                $
                                {(total && fixed(total, 2, 2)) ||
                                  fixed('0.0', 2, 2)}
                              </td>
                              <td></td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  )}

                  <Col lg={12}>
                    <div className='table-responsive text-center'>
                      <Table className='table mb-0 cust-tab'>
                        <thead className='table-color'>
                          <tr>
                            <th colSpan={3}>GRAND TOTAL</th>
                            <th style={{ textAlign: 'right' }}>
                              $
                              {fixed(total + final_price, 2, 2) ||
                                fixed(0.0, 2, 2)}
                            </th>
                            <th className='w-10'></th>
                          </tr>
                        </thead>
                      </Table>
                    </div>
                  </Col>

                  <Row>
                    <Col lg='12'>
                      <div className='card shadow-only-hover mt-8 shop-card text-center'>
                        <div className='card-body'>
                          <h4 className='mb-3'>
                            NOTE - ALL ENTRIES ARE FINAL AND NON-REFUNDABLE.
                          </h4>
                          <p>
                            You as the exhibitor understands and is agreeing to
                            the fact that your entry is FINAL and NON-REFUNDABLE
                            for any reason. It is at the discrection of the host
                            club if they will refund any part of or all monies,
                            but Easy2Show will not guarantee that the show
                            might/will and Easy2Show does not refund any
                            monies/fees.
                          </p>

                          <p>
                            If the show cancels or postpones, for any reason,
                            your paid entry is still active. It can be modified
                            for the furture show date within the same animal
                            "category". To make changes: "login" as a user, goto
                            "your participation", search for the Show/Event and
                            click "update" next to the animal you want to
                            update. You need to update each animal in each
                            Event. Changing an animal's information in Event A
                            will NOT automatically change it in Event B.
                          </p>

                          <p>
                            Any changes after the show deadline is at the
                            discretion of the show committee. Easy2Show does NOT
                            guarantee that any change requested will be granted
                            by the show committee.
                          </p>

                          <Col xl={12}>
                            <div className='mt-3 agree-check text-left'>
                              <input
                                className='form-check-input mr-2'
                                type='checkbox'
                                id='check'
                                value=''
                                onChange={(e) => {
                                  setValue((prev) => !prev)
                                }}
                              />
                              <label
                                className={'form-check-label p-r-3 mt-1'}
                                htmlFor='check'
                              >
                                {' '}
                                ACCEPT
                              </label>
                              {error && (
                                <div className='text-danger'>{error}</div>
                              )}
                            </div>
                          </Col>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Col lg={12} className='text-center mt-5'>
                    {/* <Link to="/auth-events">
                      <button type="button" className="btn btn-primary">
                        CONTINUE SHOPPING
                      </button>
                    </Link> */}

                    <button
                      type='button'
                      disabled={isLoading || isDisabled}
                      className={classnames('btn btn-primary btn-easy', {})}
                      onClick={() => paymentData()}
                    >
                      PAY / CHECKOUT
                    </button>
                  </Col>
                </Row>
              ) : loading ? (
                <Row>
                  <Col lg={12} className='easy-middle-btn'>
                    <div
                      className='justify-content-center align-items-center title-center'
                      style={{
                        textAlign: 'center',
                        fontSize: 'xx-large',
                        height: '60vh',
                      }}
                    >
                      Loading ...
                    </div>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col lg={12} className='easy-middle-btn'>
                    <div
                      className='justify-content-center align-items-center title-center'
                      style={{
                        textAlign: 'center',
                        fontSize: 'xx-large',
                        height: '60vh',
                      }}
                    >
                      Shopping cart is empty
                    </div>
                  </Col>
                </Row>
              )}
            </Container>
          </section>
          <Modal
            className='sucess_modal'
            isOpen={modal_center}
            toggle={() => {
              // contact_center_modal();
            }}
            centered={true}
          >
            <div className='modal-header'>
              <h4>&nbsp;</h4>
              <button
                type='button'
                onClick={() => {
                  setmodal_center(false)
                }}
                className='close close-btn'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body'>
              <Row className='mb-2 text-center'>
                <Col lg={12}>
                  <h4>THANK YOU</h4>
                  <p className='m-0 '>
                    <img src={checkImg} alt='sucess' className='p-r-3' />
                  </p>
                  <p className='m-0 '>Dear, {username || ''}</p>
                  <p className='m-0 '>
                    Thank you for participating in the event.
                  </p>
                  <p>The check-in sheet has been sent to your e-mail.</p>
                  {/* <p>Thank you, we have received your payment.</p> */}
                </Col>
              </Row>
            </div>
          </Modal>
        </div>
        {/* ) : (
          <div className='page-content bg-gray-101 d-flex align-items-center justify-content-center'>
            <h3>Loading...</h3>
          </div>
        )} */}
      </React.Fragment>
    </React.Fragment>
  )
}

export default ShoppingCart
