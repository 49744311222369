import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import {
  Button,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from 'reactstrap'
import { paymentSuccess, premierUsers, setCloseModal } from '../../../store/actions'

const PurchasedRedirect = (props) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const query = new URLSearchParams(location.search)
  const paymentIntentId = query.get('payment_intent')
  const { showModal, modalContent } = useSelector((state) => state.Modal)
  const userData = useSelector((state) => state.Login)
  useEffect(() => {
    const data = {
      history: history,
      body: {
        payment_intent_id: paymentIntentId,
      },
    }
    dispatch(paymentSuccess(data))
  }, [])

  // paymentIntentSuccess

  const onClose = () => {
    if (userData?.user?.id) {
      let data = {
        user_id: userData?.user.id,
      }
      dispatch(premierUsers(data))
    }
    dispatch(
      setCloseModal({
        showModal: false,
        modalContent: '',
      })
    )
    history.push('/')
  }
  return (
    <section
      style={{ height: '80vh' }}
      className=' section mt-10 d-flex justify-content-center align-content-center'
    >
      <Container fluid>
        <Row>
          <Col
            md='12'
            className='d-flex justify-content-center align-content-center'
          >
            <h4>Loading...</h4>
          </Col>
        </Row>
        <Modal
          isOpen={showModal}
          className='modal-dialog'
          fullscreen={true}
          centered
        >
          <ModalBody
            // style={{ height: '90vh', opacity: '0.7' }}
            className='bg-white rounded-2'
          >
            <Row>
              <Col md='12' className='text-center mt-3'>
                <h4>{modalContent}</h4>
              </Col>
              <Col md='12' className='text-center mt-3'>
                <Button size='sm' color='success' onClick={onClose}>
                OKAY
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </Container>
    </section>
  )
}

export default PurchasedRedirect
