export const totalPrice = (product) => {
  let price = 0
  if (product && product.length) {
    price = product.reduce((sum, i) => {
      return sum + (i.amount * i?.unit || 0)
    }, 0)
  }

  return Number(price.toFixed(2))
}

export const shopCartPrice = (product) => {
  let price = 0
  if (product && product.length) {
    price = product.reduce((sum, i) => {
      return sum + (i.unit_price * i?.units || 0)
    }, 0)

    return Number(price.toFixed(2))
  }
}

export const hasContinueBTN = (product) => {
  let disabled = true
  // debugger
  if (product && product.length) {
    for (let i = 0; i < product.length; i++) {
      const prod = product[i]
      if (prod.unit) {
        disabled = false
        break
      }
    }
  }

  return disabled
}

export const discountPrices = (discount) => {
  let priceArr
  let price = 0
  let discountPrice = 0
  let finalPrice = 0
  if (discount && discount.length) {
    priceArr = discount.reduce(function (priceArr, arr, cI) {
      priceArr.push({
        total_cost: arr.final_total_cost,
        total_discount: arr.final_total_discount,
        finalPrice: arr.final_cost,
      })

      return priceArr
    }, [])
    price = priceArr.map((price) => price.total_cost)
    price = price.reduce((partialSum, a) => partialSum + a, 0)
    //
    discountPrice = priceArr.map((price) => price.total_discount)
    discountPrice = discountPrice.reduce((partialSum, a) => partialSum + a, 0)
    //
    finalPrice = priceArr.map((price) => price.finalPrice)
    finalPrice = finalPrice.reduce((partialSum, a) => partialSum + a, 0)
  }

  return {
    price: price,
    discountPrice: discountPrice,
    finalPrice: finalPrice,
  }
}

export const eventTotal = (product) => {
  let price = 0
  if (product && product.length) {
    price = product.reduce((sum, i) => {
      return sum + (i.total_entry * i?.total_exh || 0)
    }, 0)
  }
  return price
}

export const fixed = (v, left, right) => {
  return Math.abs(v)
    .toFixed(right)
    .padStart(left + right + 1, '0')
}

//

// ******************************************************************  Validation **************************************************************

export const EMAIL_REGEX = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
)
export const PASSWORD_REGEX = new RegExp(
  /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).{8,15}$/
)

export const ONLY_CHAR_REGEX = new RegExp(/^[A-Za-z -]*$/)

export const ONLY_MOBILE_NUMBER_REGEX = new RegExp(
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
)

export const URL_REGEX = () =>
  new RegExp(
    /\b(?:https?:\/\/)?(?:www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\b(?:\/[a-zA-Z0-9+&@#\/%?=~-]*)?/g
  )
export const ONLY_NUMBER_REGEX = new RegExp(/^[0-9]*$/)
export const PRICE_REGEX = new RegExp(/^\d+(\.\d{1,2})?$/)
export const ONLY_ALLOW_NUMBER_AND_CHAR = new RegExp(/^[a-zA-Z0-9 -]+$/)
export const ONLY_NUMBER_WITH_DASH = new RegExp(/^[0-9][0-9\-\+\.]*$/)
export const isValidUrl = (url) => {
  try {
    new URL(url)
  } catch (e) {
    return false
  }
  return true
}

export const ARBA_REGEX = new RegExp(/^[a-zA-Z0-9]*$/)
export const ARBA_REGEX_WITH_SPECIAL_CHAR = new RegExp(
  /^[ A-Za-z0-9_@./#&*()-]*$/
)
export const ADDRESS_REGEX = new RegExp(/^[a-zA-Z0-9_,]+([ a-zA-Z,&0-9_-]+)*$/)
export const REGEX_CHAR_WITH_SPECIAL_CHAR = new RegExp(
  /^[ A-Za-z0-9_@./#&*()-]*$/
)
export const US_ZIP_REG_EX = new RegExp(/^\d{5}(?:|[-\s]\d{4})$/g)
export const CA_ZIP_REG_EX = new RegExp(
  /^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/g
)

export const SUPPORTED_FORMATS_IMG = ['image/jpeg', 'image/png', 'image/gif']
export const SUPPORTED_FORMATS_DOC = ['application/pdf']
export const MAX_FILE_SIZE = 4096 // 4MB
export const MAX_FILE_SIZE_256 = 256 // 256KB
export const MAX_FILE_SIZE_5 = 5096 // 5MB
export const MAX_FILE_SIZE_4 = 4096 // 4MB



