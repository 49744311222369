import React from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

const Footer = () => {
  const location = useLocation();

  return (
    <React.Fragment>
      {location.pathname !== '/clerk_login' && (
        <footer className="footer-page">
          <div>
            <div className="row">
              <div className="col-md-6">
                <div className="text-center1 d-sm-block rights">
                  © {new Date().getFullYear()} Easy2Show. All Rights Reserved.
                </div>
              </div>
              <div className="col-md-6">
                <div className="text-right d-sm-block rights">
                  <Link to="/terms_conditions" className="mb-3">
                    Website Terms, Conditions & Privacy Policy
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </footer>
      )}
    </React.Fragment>
  );
};

export default Footer;
