import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'
import { setPremiumModal } from '../../../../store/actions'
import { Link } from 'react-router-dom'

const PremiumModal = () => {
  const dispatch = useDispatch()
  const { showModal } = useSelector((state) => state.PremierMembership.Modal)
  const { user } = useSelector((state) => state.Login)

  return (
    <Modal isOpen={showModal} centered>
      <div
        className='modal-header bg-white justify-content-end'
        style={{ borderRadius: '10px 10px 0 0' }}
      >
        <button
          type='button'
          onClick={() => {
            dispatch(
              setPremiumModal({
                showModal: false,
              })
            )
          }}
          className='close close-btn'
          data-dismiss='modal'
          aria-label='Close'
        >
          <span aria-hidden='true'>&times;</span>
        </button>
      </div>
      <ModalBody className='bg-white' style={{ borderRadius: 0 }}>
        <strong>
          Unlock exclusive access! Join our premier membership today!
        </strong>
      </ModalBody>
      <ModalFooter className='bg-white' style={{ justifyContent: 'center' }}>
        <Link
          to={user?.id ? '/premier-membership-auth' : '/premier-membership'}
          className='btn btn-sm btn-info'
          title='Find Out More'
          onClick={() => {
            dispatch(
              setPremiumModal({
                showModal: false,
              })
            )
          }}
        >
          FIND OUT MORE
        </Link>
      </ModalFooter>
    </Modal>
  )
}

export default PremiumModal
