import React, { useMemo } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner } from 'reactstrap'
import {
  getLiveEventBreedAsFurDetail,
  getLiveEventBreedDetail,
  LiveEventExhibitorList,
  LiveEventExhibitorListForExcel,
} from '../../../../store/actions'
import _ from 'lodash'
import Group from './Group/Group'
import Variety from './Variety/Variety'
import Class from './Class/Class'
import FurType from './FurType/FurType'
import FurColor from './FurColor/FurColor'
import Placing from './Placing/Placing'
import FinalBreedList from './FinalBreedList/FinalBreedList'

const BreedDetail = ({ breedData, breed, showAndEvent }) => {
  const dispatch = useDispatch()
  const BreedHook = FinalBreedList()

  ////////////////////////////////
  const { breedDetail, breedDetailLoading, reloadData } = useSelector(
    (state) => ({
      breedDetail: state?.LiveEvent?.breedData?.breedDetail || {},
      breedDetailLoading: state?.LiveEvent?.breedData?.loading,
      reloadData: state?.LiveEvent?.reloadData,
    })
  )
  ////////////////////////////////////////////
  useEffect(() => {
    if (showAndEvent?.showData?.option_name === 'generic') {
      if (
        breed?.record_type === 'breed' &&
        breedData?.breed_id === breed.breed_id
      ) {
        const data = {
          body: {
            breed_id: breed?.breed_id || '',
            judging_level: 'breed',
          },

          params: {
            event_type: showAndEvent?.eventData?.event_type,
            judging_order_id: showAndEvent?.showData?.judging_order_id,
            sheet_option: showAndEvent?.showData?.option_name,
            show_id: showAndEvent?.showData?.show_id,
            breed_id: breedData?.breed_id,
          },
        }
        dispatch(getLiveEventBreedDetail(data))
        const exhibitorData = {
          params: {
            event_type: showAndEvent?.eventData?.event_type,
            show_id: showAndEvent?.showData?.show_id,
          },
          body: {
            judging_order_id: showAndEvent?.showData?.judging_order_id,
            placing_type: 'class',
            judging_level: 'breed',

            breed_id: breed.breed_id,
          },
        }

        dispatch(LiveEventExhibitorList(exhibitorData))
      }
      if (
        breed?.record_type === 'fur_as_breed' &&
        breedData?.fur_type_id === breed.fur_type_id
      ) {
        const data = {
          // body: {
          //   breed_category_id_list: [breedData?.breed_category_id],
          //   breed_id_list: [breedData?.breed_id],
          //   event_type: showAndEvent?.eventData?.event_type,
          //   tree: 0,
          //   visible: 1,
          //   sort_by_class: 1,
          // },

          params: {
            event_type: showAndEvent?.eventData?.event_type,
            judging_order_id: showAndEvent?.showData?.judging_order_id,
            sheet_option: showAndEvent?.showData?.option_name,
            show_id: showAndEvent?.showData?.show_id,
            fur_type_id: breedData?.fur_type_id,
          },
        }
        dispatch(getLiveEventBreedAsFurDetail(data))
        const exhibitorData = {
          params: {
            event_type: showAndEvent?.eventData?.event_type,
            show_id: showAndEvent?.showData?.show_id,
          },
          body: {
            judging_order_id: showAndEvent?.showData?.judging_order_id,
            placing_type: 'fur',
            judging_level: 'fur',
            fur_type_id: breedData?.fur_type_id,
          },
        }

        dispatch(LiveEventExhibitorList(exhibitorData))
        //
        //
      }
    }
    if (showAndEvent?.showData?.option_name === 'excel') {
      if (
        breed?.record_type === 'breed' &&
        breedData?.breed_id === breed.breed_id
      ) {
        const data = {
          body: {
            breed_id: breed?.breed_id || '',
            judging_level: 'breed',
          },

          params: {
            event_type: showAndEvent?.eventData?.event_type,
            judging_order_id: showAndEvent?.showData?.judging_order_id,
            sheet_option: showAndEvent?.showData?.option_name,
            show_id: showAndEvent?.showData?.show_id,
            breed_id: breedData?.breed_id,
          },
        }
        dispatch(getLiveEventBreedDetail(data))
        const exhibitorData = {
          params: {
            event_type: showAndEvent?.eventData?.event_type,
            show_id: showAndEvent?.showData?.show_id,
          },
          body: {
            judging_order_id: showAndEvent?.showData?.judging_order_id,
            placing_type: 'class',
            judging_level: 'breed',

            breed_id: breed.breed_id,
          },
        }

        dispatch(LiveEventExhibitorListForExcel(exhibitorData))
      }
      if (
        breed?.record_type === 'fur_as_breed' &&
        breedData?.fur_type_id === breed.fur_type_id
      ) {
        const data = {
          // body: {
          //   breed_category_id_list: [breedData?.breed_category_id],
          //   breed_id_list: [breedData?.breed_id],
          //   event_type: showAndEvent?.eventData?.event_type,
          //   tree: 0,
          //   visible: 1,
          //   sort_by_class: 1,
          // },

          params: {
            event_type: showAndEvent?.eventData?.event_type,
            judging_order_id: showAndEvent?.showData?.judging_order_id,
            sheet_option: showAndEvent?.showData?.option_name,
            show_id: showAndEvent?.showData?.show_id,
            fur_type_id: breedData?.fur_type_id,
          },
        }
        dispatch(getLiveEventBreedAsFurDetail(data))
        const exhibitorData = {
          params: {
            event_type: showAndEvent?.eventData?.event_type,
            show_id: showAndEvent?.showData?.show_id,
          },
          body: {
            judging_order_id: showAndEvent?.showData?.judging_order_id,
            placing_type: 'fur',
            judging_level: 'fur',
            fur_type_id: breedData?.fur_type_id,
          },
        }

        dispatch(LiveEventExhibitorListForExcel(exhibitorData))
        //
        //
      }
    }
  }, [breedData, reloadData, showAndEvent?.showData?.option_name])

  ////////////////////////////////

  const finalBreed = useMemo(() => {
    let obj = {}
    if (!breedDetailLoading && _.size(breedDetail)) {
      obj = BreedHook.breedDetail(breedDetail, breed?.record_type)
    }
    return obj
  }, [breedDetailLoading, breedDetail, BreedHook, breed?.record_type])
  return (
    <div>
      {!breedDetailLoading && _.size(finalBreed) ? (
        <>
          {breed?.record_type === 'breed' ? (
            <div>
              {_.has(finalBreed, 'groupList') &&
              Array.isArray(finalBreed.groupList)
                ? _.uniqBy(finalBreed.groupList, 'group_id').map((group) => {
                    return (
                      <Group
                        key={group.group_id}
                        breed={group}
                        recordType={breed?.record_type}
                      />
                    )
                  })
                : null}
              {_.has(finalBreed, 'varietyList') &&
              Array.isArray(finalBreed.varietyList)
                ? _.uniqBy(finalBreed.varietyList, 'variety_id').map(
                    (variety) => {
                      return (
                        <Variety
                          key={variety.variety_id}
                          breed={variety}
                          recordType={breed?.record_type}
                        />
                      )
                    }
                  )
                : null}
              {_.has(finalBreed, 'classList') &&
              Array.isArray(finalBreed.classList)
                ? finalBreed.classList.map((classes) => {
                    return (
                      <Class
                        key={classes.variety_id}
                        breed={classes}
                        recordType={breed?.record_type}
                      />
                    )
                  })
                : null}
              {_.has(finalBreed, 'furTypeList') &&
              Array.isArray(finalBreed.furTypeList)
                ? finalBreed.furTypeList.map((furType) => {
                    return (
                      <FurType
                        key={furType.fur_type_id}
                        breed={furType}
                        recordType={breed?.record_type}
                      />
                    )
                  })
                : null}
            </div>
          ) : breed?.record_type === 'fur_as_breed' ? (
            <div>
              {_.has(finalBreed, 'furList') && Array.isArray(finalBreed.furList)
                ? finalBreed.furList.map((fur) => {
                    return (
                      <FurColor
                        key={fur.fur_id}
                        breed={fur}
                        recordType={breed?.record_type}
                      />
                    )
                  })
                : null}
              {_.has(finalBreed, 'furList') &&
              Array.isArray(finalBreed?.furList) &&
              !finalBreed?.furList.length ? (
                <Placing
                  tabType='fur'
                  openActiveTab
                  recordType={breed?.record_type}
                />
              ) : null}
            </div>
          ) : null}
        </>
      ) : breedDetailLoading ? (
        <div className='text-center'>
          <Spinner size='sm' />
        </div>
      ) : !_.size(finalBreed) ? (
        <div className='text-center'>
          <h5>No live placing found</h5>
        </div>
      ) : null}
    </div>
  )
}

export default BreedDetail
