import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardHeader,
  RadioGroup,
  SelectField,
  Table,
  TextField,
} from '../Atoms'
import { CardBody, Col, Row, Spinner } from 'reactstrap'
import Breadcrumb from '../../../common/Breadcrumb'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import {
  deletePedigree,
  getAllBreedList,
  pedigreeList,
  showAndClosePedigreeModal,
  updatePedigreeUpdate,
} from '../../../store/actions'
import _ from 'lodash'
import { Field, Form, Formik, useFormikContext } from 'formik'
import Pagination from '../../../HerdManagement/Pagination/Pagination'
import { UrlEncodeHook } from '../../../hooks'
import Swal from 'sweetalert2'
import { toast } from 'react-toastify'
import PedigreePdf from './PedigreePdf/PedigreePdf'
import { getDateWithFormat } from '../../../helpers/date_helper'
import { nextPage } from '../../../store/HerdManagement/Pagination/action'

const Pedigree = () => {
  const { urlEncode } = UrlEncodeHook()
  const dispatch = useDispatch()

  const [isReloadComponentCall, setIsReloadComponentCall] = useState(false)
  const [reload, setReload] = useState(false)
  const [isDelete, setIsDelete] = useState(false)

  const { PedigreeList, loading, page, BreedList, limit } = useSelector(
    (state) => {
      return {
        PedigreeList: state.herdManagement.Pedigree.pedigree.pedigreeList,
        BreedList:
          state.herdManagement.Pedigree.configureData?.breed_list || [],
        loading: state.herdManagement.Pedigree.pedigree.loading,
        page: state?.herdManagement?.pagination?.page || 1,
        limit: state?.herdManagement?.pagination?.limit || 1,
      }
    }
  )
  useEffect(() => {
    // const data = {
    //   params: {
    //     _page: 1,
    //     _limit: LIMIT,
    //     // _sort: 'rabbit_name',
    //     // _order: 'ASC',
    //   },
    //   data: {
    //     archieve: 'pedigree',
    //   },
    // }
    // dispatch(pedigreeList(data))
    const breedData = {
      params: {
        published: 1,
      },
    }
    dispatch(getAllBreedList(breedData))
  }, [])

  const paginationClick = (page) => {
    const { selected } = page
    const finalPage = (Number(selected) || 0) + 1
    const res = dispatch(nextPage(finalPage))
    if (res) {
      setReload(!reload)
      setIsReloadComponentCall(true)
      setIsDelete(false)
    }
    // const data = {
    //   params: {
    //     _limit: LIMIT,
    //     _page: finalPage,
    //     // _sort: 'rabbit_name',
    //     // _order: 'ASC',
    //   },
    //   data: {
    //     archieve: 'pedigree',
    //   },
    // }
    // dispatch(pedigreeList(data))
  }

  const deletePedigreeApi = (body) => {
    return new Promise((resolve, reject) => {
      dispatch(
        deletePedigree(body, (err, data) => {
          if (err) {
            reject(err)
          } else {
            resolve(data)
          }
        })
      )
    })
  }
  const updatePedigreeApi = (body) => {
    return new Promise((resolve, reject) => {
      dispatch(
        updatePedigreeUpdate(body, (err, data) => {
          if (err) {
            reject(err)
          } else {
            resolve(data)
          }
        })
      )
    })
  }
  const onDelete = (ID) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      width: '380px',
      showCancelButton: true,
      confirmButtonColor: '#062575',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const data = {
            params: { id: ID },
          }
          const res = await deletePedigreeApi(data)

          if (res) {
            toast.success(res.data)
            setIsDelete(true)
            setReload(!reload)
            setIsReloadComponentCall(true)
          }
        } catch (err) {
          if (Array.isArray(err)) {
            toast.error(_.get(_.first(err), 'msg'))
          } else if (err.response.data) {
            toast.error(err.response.data)
          } else if (err) {
            toast.error(err)
          }
        }
      }
    })
  }

  const onStatusChange = async (e, pedigree) => {
    const { value } = e.target

    try {
      const updateObj = {
        params: { id: pedigree.pedigree_id },
        data: {
          archieve: value,
          rabbit_data: [],
        },
      }
      const res = await updatePedigreeApi(updateObj)
      if (res) {
        toast.success(res.data)
        setReload(!reload)
        setIsDelete(true)
      }
    } catch (err) {
      if (Array.isArray(err)) {
        toast.error(_.get(_.first(err), 'msg'))
      } else if (err.response.data) {
        toast.error(err.response.data)
      } else if (err) {
        toast.error(err)
      }
    }
  }

  return (
    <Card className='layout'>
      <Row>
        <CardHeader>
          <Row>
            <Breadcrumb title='Pedigree' />
          </Row>
        </CardHeader>
        <CardBody>
          {!_.size(BreedList) ? (
            <Col className='mb-3 text-center' md='12'>
              <h5 className='text-danger'>
                Before creating a pedigree, please ensure that you have
                completed the breed configuration.
              </h5>
            </Col>
          ) : (
            <Row>
              <Col md='3' className='mb-3'>
                <Formik initialValues={{ search: '' }}>
                  {(formProps) => (
                    <Form className='needs-validation'>
                      <SearchComponent />
                      {isReloadComponentCall ? (
                        <ReloadComponent reload={reload} isDelete={isDelete} />
                      ) : null}
                      <Row>
                        <Col md='12' lg='12' sm='12'>
                          <Field
                            name='search'
                            placeholder='Search By Breed Name'
                            type='text'
                            id='search'
                            // isSearch
                            onChange={(e) => {
                              // console.log(e)
                              formProps.setFieldValue('search', e.target.value)
                            }}
                            component={TextField}
                          />
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Col>
              <Col md='9' className='mb-3 text-end'>
                <Link
                  to='/herd-management/pedigree/create'
                  className='btn btn-primary btn-sm'
                  title='Create'
                >
                  CREATE
                </Link>
              </Col>
            </Row>
          )}
          <Col md={12}>
            <Table className='table-responsive'>
              <thead>
                <tr>
                  <th className='w-1'>No.</th>
                  <th>Breed Name</th>
                  <th>Ear/Tag #</th>
                  <th>Animal Name</th>
                  <th>Gender</th>
                  <th>Date of Birth</th>
                  <th className='w-15'>Status</th>
                  <th>PDF</th>
                  <th>Created On</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {!loading && _.size(PedigreeList) ? (
                  PedigreeList.map((pedigree, i) => {
                    const rabbit = _.first(pedigree?.rabbit_data)
                    return (
                      <tr>
                        <td className='w-1'>{limit * (page - 1) + i + 1}</td>
                        <td>{rabbit?.breed_name || ''}</td>
                        <td>{rabbit?.ear_no || ''}</td>
                        <td>{rabbit?.rabbit_name || ''}</td>
                        <td>
                          {rabbit?.rabbit_gender === 'M' ? 'Buck' : 'Doe'}
                        </td>
                        <td>
                          {rabbit.date_of_birth
                            ? moment(new Date(rabbit.date_of_birth)).format(
                                'MMM Do YYYY'
                              )
                            : '-'}{' '}
                        </td>
                        <td>
                          <Formik initialValues={{}}>
                            {() => {
                              return (
                                <Row>
                                  <Col
                                    md='12'
                                    className='d-flex flex-row flex-wrap align-content-center align-items-center pedi-select'
                                  >
                                    <Formik
                                      initialValues={{
                                        archieve: pedigree?.archieve || '',
                                      }}
                                      enableReinitialize
                                    >
                                      {({ values }) => {
                                        return (
                                          <Form
                                            onChange={(e) =>
                                              onStatusChange(e, pedigree)
                                            }
                                          >
                                            <Field
                                              name='archieve'
                                              type='select'
                                              id='archieve'
                                              onlyShowOption
                                              option={[
                                                {
                                                  label: 'Select',
                                                  value: '',
                                                },
                                                {
                                                  label: 'Sold',
                                                  value: 'sold',
                                                },
                                                {
                                                  label: 'Other',
                                                  value: 'other',
                                                },
                                              ]}
                                              component={SelectField}
                                            />
                                          </Form>
                                        )
                                      }}
                                    </Formik>
                                  </Col>
                                </Row>
                              )
                            }}
                          </Formik>
                        </td>
                        <td>
                          <Button
                            size='sm'
                            onClick={() =>
                              dispatch(
                                showAndClosePedigreeModal({
                                  showModal: true,
                                  modalType: '',
                                  ModalContent: {
                                    form: pedigree,
                                    childRabbit: null,
                                  },
                                })
                              )
                            }
                          >
                            PDF
                          </Button>
                        </td>
                        <td>
                          {moment(
                            getDateWithFormat(rabbit?.created_on || '')
                          ).format('MMM Do YYYY LT')}
                        </td>
                        <td>
                          <span>
                            <Link
                              to={`/herd-management/pedigree/update/${urlEncode(
                                pedigree.pedigree_id
                              )}?p=${urlEncode(pedigree.pedigree_id)}`}
                            >
                              <img
                                src={`${process.env.PUBLIC_URL}/assets/img/icons/update.png`}
                                alt='update'
                                className={`mb-2 p-r-3 `}
                                title='Update'
                              />
                            </Link>
                          </span>
                          <span>
                            <img
                              src={`${process.env.PUBLIC_URL}/assets/img/icons/delete.png`}
                              alt='delete'
                              role='button'
                              onClick={() => onDelete(pedigree.pedigree_id)}
                              className={`mb-2 p-r-3`}
                              title='Delete'
                            />
                          </span>
                        </td>
                      </tr>
                    )
                  })
                ) : loading ? (
                  <tr>
                    <td colSpan={10} className='text-center'>
                      <Spinner size='sm' />
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td colSpan={10} className='text-center'>
                      No pedigree found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            <Pagination paginationClick={paginationClick} />
          </Col>
        </CardBody>
        <PedigreePdf />
      </Row>
    </Card>
  )
}

export default Pedigree

export const SearchComponent = () => {
  const dispatch = useDispatch()
  const { values } = useFormikContext()

  const { limit } = useSelector((state) => {
    return {
      limit: state?.herdManagement?.pagination?.limit || 1,
    }
  })
  useEffect(() => {
    // if (values?.search) {

    const data = {
      params: {
        _page: 1,
        _limit: limit,
        // _sort: 'animal_name',
        // _order: 'ASC',
        breed_name: values?.search,
      },
      data: {
        archieve: 'pedigree',
      },
    }
    dispatch(pedigreeList(data))
  }, [limit, values?.search])
  return null
}

export const ReloadComponent = ({ reload, isDelete }) => {
  const dispatch = useDispatch()
  const { values } = useFormikContext()
  const { PedigreeList, page, limit } = useSelector((state) => {
    return {
      PedigreeList: state.herdManagement.Pedigree.pedigree.pedigreeList,
      BreedList: state.herdManagement.Pedigree.configureData?.breed_list || [],
      loading: state.herdManagement.Pedigree.pedigree.loading,
      page: state?.herdManagement?.pagination?.page || 1,
      limit: state?.herdManagement?.pagination?.limit || 1,
    }
  })
  useEffect(() => {
    let finalPage = page
    if (Array.isArray(PedigreeList) && PedigreeList.length === 1 && isDelete) {
      finalPage -= 1
    }
    const data = {
      params: {
        _page: finalPage,
        _limit: limit,
        // _sort: 'rabbit_name',
        // _order: 'ASC',
        breed_name: values?.search,
      },
      data: {
        archieve: 'pedigree',
      },
    }
    dispatch(pedigreeList(data))
  }, [reload])
  return null
}
