import React, { useEffect, useMemo, useState } from 'react'
import { Col, Row, Input } from 'reactstrap'
import classnames from 'classnames'
import Select from 'react-select'
// import deleteImg from '../../../assets/images/users/delete.png'
import _ from 'lodash'
import { showListByShowType, varietyMemo } from '../../../helpers/helper'

import { useParams } from 'react-router-dom'
import { post } from '../../../helpers/api_helper'
import { UrlEncodeHook } from '../../../hooks'
// import updateImg from '../../../assets/images/users/update.png'
//Import

function IsAlphaNumeric(e) {
  var val = e.target.value
  if (!val.match(/^[a-zA-Z0-9,*]+$/g)) {
    e.preventDefault()
    return false
  } else {
    return true
  }
}

const List = (props) => {
  let { eventId } = useParams()
  const { urlDecode } = UrlEncodeHook()
  eventId = urlDecode(eventId)

  const {
    eventType,
    showData,
    list,
    exhibitorList,
    onChange,
    updateHasFur,
    error,
    onEdit,
    onSave,
    furDisable,
    disableGroupToClass,
    disabledEr,
  } = props
  // console.log('list.display_fur_data', list.display_fur_data)
  const [breedCategoryData, setBreedCategoryData] = useState([])
  const [breedData, setBreedData] = useState([])
  const [groupList, setGroupList] = useState([])
  const [varietyList, setVarietyList] = useState([])
  const [classList, setClassList] = useState([])
  const [furTypeList, setFurTypeList] = useState([])
  const [furList, setFurList] = useState([])
  // getBreed Category Data
  useEffect(() => {
    const fun = async () => {
      if (list.show_id) {
        let data = {
          show_id_list: Array.isArray(list.show_id)
            ? list.show_id
            : [list.show_id],
          visible: 1,
        }
        const breedData = await post(
          `/api/v1/events/${eventId}/breeds/breed_category`,
          data
        )
        if (breedData && Array.isArray(breedData.data)) {
          setBreedCategoryData(breedData.data)
        } else {
          setBreedCategoryData([])
        }
      } else {
        setBreedCategoryData([])
        setBreedData([])
        setGroupList([])
        setVarietyList([])
        setClassList([])
        setFurTypeList([])
        setFurList([])
      }
    }
    fun()
  }, [list.show_id])

  // get breed Data
  useEffect(() => {
    if (list.show_id && list.breed_category_id) {
      let breedFun = async () => {
        let data = {
          show_id_list: Array.isArray(list.show_id)
            ? list.show_id
            : [list.show_id],
          breed_category_id_list: [list.breed_category_id],
          visible: 1,
        }
        const breedData = await await post(
          `/api/v1/events/${eventId}/breeds/breed`,
          data
        )

        if (breedData && Array.isArray(breedData.data)) {
          setBreedData(breedData.data)
        }
      }
      breedFun()
    } else {
      setBreedData([])
      setGroupList([])
      setVarietyList([])
      setClassList([])
      setFurTypeList([])
      setFurList([])
    }
  }, [list.show_id, list.breed_category_id])
  //
  useEffect(() => {
    // debugger
    if (list.breed_category_id && list.breed_id && eventType) {
      setGroupList([])
      setVarietyList([])
      setClassList([])
      setFurTypeList([])
      setFurList([])
      const breedDetailFun = async () => {
        let data = {
          breed_category_id_list: [list.breed_category_id],
          breed_id_list: [list.breed_id],
          event_type: eventType,
          tree: 0,
          visible: 1,
          sort_by_class: 1,
        }

        const breed = await post(
          `/api/v1/breed-setup/classification/breed-hierarchy`,
          data
        )
        if (breed && Array.isArray(breed.data)) {
          let obj = _.first(breed.data)
          if (obj?.groupList && Array.isArray(obj.groupList)) {
            let group = _.unionBy(obj.groupList, (obj) => obj.group_id)
            setGroupList(group)
          }
          if (obj?.varietyList && Array.isArray(obj.varietyList)) {
            setVarietyList(obj.varietyList)
          }
          if (obj?.classList && Array.isArray(obj.classList)) {
            setClassList(obj.classList)
          }
          if (
            obj?.furTypeList &&
            Array.isArray(obj.furTypeList) &&
            obj.furTypeList.length
          ) {
            setFurTypeList(obj.furTypeList)

            // updateHasFur(list.list_id, true, obj.furTypeList)
          }
          if (
            obj?.furList &&
            Array.isArray(obj.furList) &&
            obj.furList.length
          ) {
            setFurList(obj.furList)
            // updateHasFur(list.list_id, true)
          }
          if (
            (Array.isArray(obj?.furList) && obj?.furList.length) ||
            (Array.isArray(obj?.furTypeList) && obj?.furList.length)
          ) {
            updateHasFur(list.list_id, true, obj.furTypeList, obj)
          }
          // if (
          //   Array.isArray(obj?.furList) &&
          //   obj.furList.length === 1 &&
          //   Array.isArray(obj?.furTypeList) &&
          //   obj.furTypeList.length === 1
          // ) {
          //   // debugger
          //   props.updateFurData(list.list_id, obj)
          // }
        }
      }
      breedDetailFun()
    } else {
      setGroupList([])
      setVarietyList([])
      setClassList([])
      setFurTypeList([])
      setFurList([])
    }
  }, [list.breed_category_id, eventType, list.breed_id])

  const showType = useMemo(() => showTypeList(showData), [showData])
  const showList = useMemo(() => {
    let show = []
    if (list.show_type) {
      show = showListByShowType(showData, list.show_type)
    }
    return show
  }, [list.show_type, showData])
  const variety = useMemo(() => {
    let final = varietyList
    if (list.group_id) {
      final = varietyMemo(varietyList, list.group_id)
    }
    return final
  }, [list.group_id, list.variety_seq, varietyList])
  const furType = useMemo(() => {
    let data = []
    if (furTypeList.length) {
      data = furTypeList.map((fur) => {
        return {
          label: fur.fur_type_name,
          value: fur.fur_type_id,
        }
      })
    }
    return data
  }, [furTypeList])

  let uniqueFurTypeList = useMemo(() => {
    if (furList.length) {
      let Unique = _.unionBy(furList, 'fur_id')

      return Unique
    }
  }, [furList])

  let err = error.find((e) => e.id === list.list_id)

  return (
    <Row>
      <Col
        xl={12}
        className={classnames({ 'box-save-new boder-none': list.isEdit })}
      >
        <Row>
          <Col xl={3}>
            <div className='mb-3'>
              <select
                className='form-control form-select text-capitalize'
                name='show_type'
                onChange={(e) => onChange(list.list_id, e)}
                value={list.show_type}
                disabled={true}
              >
                <option value=''>Select Show Type</option>
                {showType.map((s) => {
                  return (
                    <option value={s.show_type} className='text-capitalize'>
                      {s.show_type}
                    </option>
                  )
                })}
              </select>
              {err?.show_type ? (
                <span className='text-danger'>{err?.show_type}</span>
              ) : null}
            </div>
          </Col>

          <Col xl={3}>
            <div className='mb-3'>
              <Select
                placeholder='Select Show'
                isMulti={!list.previewScreen}
                // isDisable={}
                isDisabled={true}
                value={showList.filter(
                  (s) => Number(s.value) === Number(list.show_id)
                )}
                onChange={(value) =>
                  onChange(list.list_id, value, true, 'show_id')
                }
                options={showList}
                classNamePrefix='select2-selection'
              />
              {err?.show_id ? (
                <span className='text-danger'>{err?.show_id}</span>
              ) : null}
            </div>
          </Col>

          <Col xl={3}>
            <div className='mb-3'>
              <select
                className='form-control form-select'
                value={list.user_id}
                name='user_id'
                disabled={true}
                // disabled={
                //   (Boolean(list.previewScreen) && !Boolean(list.isEdit)) ||
                //   furDisable
                // }
                onChange={(e) => onChange(list.list_id, e)}
              >
                <option value=''>Select Exhibitor</option>
                {exhibitorList.map((exhi, i) => {
                  return (
                    <option
                      value={exhi.id}
                      key={i}
                    >{`${exhi.last_name} ${exhi.first_name} `}</option>
                  )
                })}
              </select>
              {err?.user_id ? (
                <span className='text-danger'>{err?.user_id}</span>
              ) : null}
            </div>
          </Col>
          <Col xl={3}>
            <div className='mb-3'>
              <Input
                type='text'
                className='form-control'
                id='ear_no'
                name='ear_no'
                value={list.ear_no}
                disabled={
                  Boolean(list.previewScreen && !Boolean(list.isEdit)) ||
                  disabledEr
                }
                onChange={(e) => {
                  let IsAlNumeric = IsAlphaNumeric(e)

                  if (IsAlNumeric === true || e.target.value === '') {
                    onChange(list.list_id, e)
                  }
                }}
                // value={inputs.ear_no || ''}
                onDrop={() => {
                  return false
                }}
                onPaste={() => {
                  return false
                }}
                // onChange={(e) => onChange(list.list_id, e)}
                placeholder='Enter Ear No.'
              />
              {err?.ear_no ? (
                <span className='text-danger'>{err?.ear_no}</span>
              ) : null}
            </div>
          </Col>
          <Col xl={3}>
            <div className='mb-3'>
              <select
                className='form-control form-select'
                name='breed_category_id'
                value={list?.breed_category_id}
                disabled={true}
                onChange={(e) => onChange(list.list_id, e)}
              >
                <option value=''>Select Category</option>
                {breedCategoryData.map((bCate) => {
                  return (
                    <option value={bCate.breed_category_id}>
                      {bCate.category_name}
                    </option>
                  )
                })}
              </select>
              {err?.breed_category_id ? (
                <span className='text-danger'>{err?.breed_category_id}</span>
              ) : null}
            </div>
          </Col>

          <Col xl={3}>
            <div className='mb-3'>
              <select
                className='form-control form-select'
                value={list.breed_id}
                name='breed_id'
                disabled={true}
                onChange={(e) => onChange(list.list_id, e)}
              >
                <option value=''>Select Breed</option>
                {breedData.map((breed) => {
                  return (
                    <option
                      value={breed.breed_id}
                      group_seq={breed.group_seq}
                      variety_seq={breed.variety_seq}
                    >
                      {breed.breed_name}
                    </option>
                  )
                })}
              </select>
              {err?.breed_id ? (
                <span className='text-danger'>{err?.breed_id}</span>
              ) : null}
            </div>
          </Col>

          {Array.isArray(groupList) && groupList.length ? (
            <Col lg={3} className='manual-entry mb-4'>
              <div>
                <select
                  type='text'
                  name='group_id'
                  id='group_id'
                  disabled={
                    (Boolean(list.previewScreen) && !Boolean(list.isEdit)) ||
                    disableGroupToClass
                  }
                  className='form-control form-select'
                  onChange={(e) => onChange(list.list_id, e)}
                  value={list?.group_id}
                >
                  <option value=''>Select Group</option>
                  {Array.isArray(groupList) &&
                    groupList?.map((item, i) => {
                      return (
                        <option value={item.group_id} key={i}>
                          {item.group_name}
                        </option>
                      )
                    })}
                </select>
                {err?.group_id ? (
                  <span className='text-danger'>{err?.group_id}</span>
                ) : null}
              </div>
            </Col>
          ) : null}
          {Array.isArray(variety) && variety.length ? (
            <Col lg={3} className='manual-entry mb-4'>
              <div>
                <select
                  type='text'
                  name='variety_id'
                  id='variety_id'
                  disabled={
                    (Boolean(list.previewScreen) && !Boolean(list.isEdit)) ||
                    disableGroupToClass
                  }
                  className='form-control form-select'
                  onChange={(e) => onChange(list.list_id, e)}
                  value={list?.variety_id}
                >
                  <option value=''>Select Variety</option>
                  {variety?.map((item, i) => {
                    return (
                      <option value={item.variety_id} key={i}>
                        {item.variety_name}
                      </option>
                    )
                  })}
                </select>
              </div>
              {err?.variety_id ? (
                <span className='text-danger'>{err?.variety_id}</span>
              ) : null}
            </Col>
          ) : null}
          {/* {Array.isArray(classList) && classList.length ? ( */}
          <Col xl={3}>
            <div className='mb-3'>
              <select
                className='form-control form-select'
                value={list.class_id}
                name='class_id'
                disabled={
                  (Boolean(list.previewScreen) && !Boolean(list.isEdit)) ||
                  disableGroupToClass
                }
                onChange={(e) => onChange(list.list_id, e)}
              >
                <option value=''>Select Class</option>
                {classList.map((classes, i) => {
                  return (
                    <option value={classes.class_id} key={i}>
                      {classes.class_name}
                    </option>
                  )
                })}
              </select>
              {err?.class_id ? (
                <span className='text-danger'>{err?.class_id}</span>
              ) : null}
            </div>
          </Col>
          {/* ) : null} */}
          {Array.isArray(furType) &&
          furType.length &&
          Array.isArray(uniqueFurTypeList) &&
          uniqueFurTypeList.length ? (
            <Col lg={3} className='manual-entry mb-4'>
              <div>
                <select
                  type='text'
                  name='has_fur'
                  // disabled={list?.isDisable}
                  disabled={
                    (Boolean(list.previewScreen) && !Boolean(list.isEdit)) ||
                    furDisable
                  }
                  className='form-control form-select'
                  onChange={(e) => onChange(list.list_id, e)}
                  value={list?.has_fur}
                >
                  <option value=''>Select Fur</option>
                  <option value={1}>Yes</option>
                  <option value={0}>No</option>
                </select>
                {err?.has_fur ? (
                  <span className='text-danger'>{err?.has_fur}</span>
                ) : null}
              </div>
            </Col>
          ) : null}

          {list.display_fur_data &&
          Number(list.has_fur) &&
          Array.isArray(furType) &&
          furType.length ? (
            <Col xl={3}>
              <div className='mb-3'>
                <Select
                  placeholder='Select Fur Type'
                  isMulti
                  isDisabled={
                    (Boolean(list.previewScreen) && !Boolean(list.isEdit)) ||
                    furDisable
                  }
                  value={furType.filter((s) =>
                    list.fur_type_id.includes(s.value)
                  )}
                  onChange={(value) =>
                    onChange(list.list_id, value, true, 'fur_type_id')
                  }
                  options={furType}
                  classNamePrefix='select2-selection'
                />
                {err?.fur_type_id ? (
                  <span className='text-danger'>{err?.fur_type_id}</span>
                ) : null}
              </div>
            </Col>
          ) : null}
          {list.display_fur_data &&
          Number(list.has_fur) &&
          Array.isArray(uniqueFurTypeList) &&
          uniqueFurTypeList.length &&
          Array.isArray(list?.fur_type_id) &&
          list.fur_type_id.length ? (
            <Col xl={3}>
              <div className='mb-3'>
                <select
                  className='form-control form-select'
                  value={list.fur_id}
                  name='fur_id'
                  disabled={
                    (Boolean(list.previewScreen) && !Boolean(list.isEdit)) ||
                    furDisable
                  }
                  onChange={(e) => onChange(list.list_id, e)}
                >
                  <option value=''>Select fur </option>
                  {uniqueFurTypeList.map((fur, i) => {
                    return (
                      <option value={fur.fur_id} key={i}>
                        {fur.fur_name}
                      </option>
                    )
                  })}
                </select>
                {err?.fur_id ? (
                  <span className='text-danger'>{err?.fur_id}</span>
                ) : null}
              </div>
            </Col>
          ) : null}
        </Row>
      </Col>
      <hr />
      {list.previewScreen ? (
        <>
          {!list.isEdit ? (
            <Col lg={12} className='text-center mt-5'>
              <button
                type='button'
                id='submit-btn'
                disabled={false}
                className='btn btn-primary btn-manual w-10'
                onClick={() => onEdit(list.list_id)}
              >
                EDIT
              </button>
            </Col>
          ) : (
            <Col lg={12} className='text-center mt-5'>
              <button
                type='button'
                id='submit-btn'
                className='btn btn-primary btn-manual w-10 bg-green'
                onClick={() => onSave(list.list_id)}
              >
                SAVE
              </button>
            </Col>
          )}
        </>
      ) : null}
    </Row>
  )
}

export default List
const showTypeList = (show) => {
  let showType = []
  if (show) {
    let data = _.uniqBy(show, function (e) {
      return e.show_type
    })
    showType = data.map((s) => ({
      show_type: s.show_type,
    }))
  }

  return showType
}
