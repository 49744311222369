import moment from 'moment'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Col, Container, Row, Spinner, Table } from 'reactstrap'
import { purchasedPremierPlanList } from '../../../store/actions'
import { useDispatch, useSelector } from 'react-redux'
import ReactPaginate from 'react-paginate'
import { getDateWithFormat } from '../../../helpers/date_helper'
const LIMIT = process.env.REACT_APP_LIMIT
const PremierPlans = () => {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.Login)
  const { purchasedMembership, loading, totalPages, totalRecords, page } =
    useSelector((state) => state.PremierMembership)

  useEffect(() => {
    if (user?.id) {
      const data = {
        body: {
          user_id: user?.id,
        },
        params: {
          _page: 1,
          _limit: LIMIT,
        },
      }
      dispatch(purchasedPremierPlanList(data))
    }
  }, [user?.id])

  const paginationClick = (page) => {
    if (user?.id) {
      let nextPage = page.selected + 1
      const data = {
        body: {
          user_id: user?.id,
        },
        params: {
          _page: nextPage,
          _limit: LIMIT,
        },
      }
      dispatch(purchasedPremierPlanList(data))
    }
  }
  return (
    <div className='page-content bg-gray-101 min-h-85'>
      <section id='about' className='section1 pages'>
        <Container fluid={true} className='auto-fit'>
          <Row>
            <Col lg={10} className='text-center'>
              <div className='page-title-box justify-content-between'>
                <h2 className='top-title mb-4'>
                  <span>Purchased Plans</span>
                </h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md='12'>
              <div className='table-responsive text-center'>
                <Table className='table mb-0 cust-tab'>
                  <thead className='table-color'>
                    <tr>
                      <th>No.</th>
                      <th>Plan Type</th>
                      <th>Purchase Date And Time</th>
                      <th>Expire Date</th>
                      {/* <th>Purchased By</th> */}
                      <th>Payment Status</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loading &&
                    Array.isArray(purchasedMembership) &&
                    purchasedMembership.length ? (
                      purchasedMembership.map((premier, i) => {
                        return (
                          <tr key={premier?.payment_premier_exhibitor_id}>
                            <td>{LIMIT * (page - 1) + i + 1}</td>
                            <td>
                              {premier.plan_type === 'premier' ? 'Premier' : ''}
                              {premier.plan_type === 'combined' ? 'Premier And Herd Management' : ''}
                              {premier.plan_type === 'herd' ? 'Herd Management' : ''}
                            </td>
                            <td>
                              {moment(
                                getDateWithFormat(premier?.created_on)
                              ).format('MMM Do YYYY LT')}
                            </td>
                            <td>
                              {moment(
                                getDateWithFormat(premier?.expires_on)
                              ).format('MMM Do YYYY')}
                            </td>
                            {/* <td>Exhibitor</td> */}
                            <td>
                              {premier?.payment_status === 'S'
                                ? 'Success'
                                : 'Fail'}
                            </td>
                            <td className='d-flex justify-content-center align-items-center border-0'>
                              {' '}
                              {/* */}
                              {premier?.plan_expired ? (
                                <>
                                  <span className='text-danger fw-bolder'>
                                    {' '}
                                    In-active
                                  </span>
                                  <Link
                                    to='/'
                                    // to='/premier-membership-renew/checkout'
                                    className='ms-2 btn btn-success btn-sm'
                                    color='success'
                                    size='sm'
                                  >
                                    Renew Plan
                                  </Link>
                                </>
                              ) : (
                                <span className='text-success fw-bolder'>
                                  {' '}
                                  Active
                                </span>
                              )}
                            </td>
                          </tr>
                        )
                      })
                    ) : loading ? (
                      <tr>
                        <td colSpan={10}>
                          <Spinner size='sm' />
                        </td>
                      </tr>
                    ) : Array.isArray(purchasedMembership) &&
                      !purchasedMembership.length ? (
                      <tr>
                        <td colSpan={10}>
                          No purchased premier membership found
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </Table>
                {totalRecords > LIMIT ? (
                  <ReactPaginate
                    breakLabel='...'
                    breakClassName='page-item'
                    breakLinkClassName='page-link'
                    nextLabel='>'
                    pageCount={totalPages || 1}
                    pageRangeDisplayed={4}
                    marginPagesDisplayed={1}
                    previousLabel='<'
                    renderOnZeroPageCount={null}
                    containerClassName='pagination mtb-4'
                    pageLinkClassName='page-link page-link'
                    pageClassName='page-item'
                    activeClassName='active'
                    nextLinkClassName='page-link'
                    previousClassName='page-item'
                    previousLinkClassName='page-link'
                    nextClassName='page-item'
                    onPageChange={paginationClick}
                    forcePage={(Number(page) || 1) - 1}
                  />
                ) : null}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  )
}

export default PremierPlans
