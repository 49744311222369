import React from 'react'
import ReactImageGallery from 'react-image-gallery'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

const ImageModal = ({ modal, imageList, modalClose }) => {
  return (
    <Modal
      isOpen={modal}
      // toggle={() => {
      //   setModal(false)
      //   setImageList([])
      // }}
      className='bg-white'
      // size='lg'
    >
      <ModalHeader
      // toggle={() => {
      //   setModal(false)
      //   setImageList([])
      // }}
      >
        Images
      </ModalHeader>
      <ModalBody>
        {Array.isArray(imageList) && imageList.length ? (
          <ReactImageGallery items={imageList} />
        ) : null}
      </ModalBody>
      <ModalFooter>
        <Button
          color='warning'
          onClick={modalClose}
          //   onClick={() => {
          //     setModal(false)
          //     setImageList([])
          //   }}
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ImageModal
