import { Field, Form, Formik, useFormikContext } from 'formik'
import React, { useEffect, useState } from 'react'
import { Col, Label, Row } from 'reactstrap'
import { Button, SelectField, TextField, Switch } from '../../Atoms'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import * as validation from '../../../../helpers/validation'
import BreedSectionForm from '../BreedSectionForm/BreedSectionForm'
import _ from 'lodash'
import { ancestorList, updateRabbit } from '../../../../store/actions'
import { toast } from 'react-toastify'

const LIMIT = process.env.REACT_APP_LIMIT

const RabbitForm = ({
  form,
  onCloseModal,
  disabled,
  setReload,
  reload,
  setIsReloadComponentCall,
}) => {
  const { page } = useSelector((state) => ({
    page: state?.herdManagement?.pagination?.page || 1,
  }))
  const dispatch = useDispatch()

  const validationSchema = Yup.object({
    pedigree_id: Yup.string().when('add_from_existing_pedigree', {
      is: (value) => value,
      then: () =>
        Yup.string().required(
          `Select existing ${form.gender === 'M' ? 'Sire' : 'Dam'} rabbit`
        ),
      otherwise: () => Yup.string().notRequired(),
    }),
    ear_no: Yup.string()
      .required(`Ear No. ${validation.REQUIRED}`)
      .trim()
      .max(50, `Ear No. ${validation.MAX_CHAR_FUN(50)}`),
    animal_name:
      form.gender === 'M'
        ? Yup.string()
            .required(`Sire name ${validation.REQUIRED}`)
            .trim()
            .max(99, `Sire name ${validation.MAX_CHAR_FUN(99)}`)
        : Yup.string()
            .required(`Dam name ${validation.REQUIRED}`)
            .trim()
            .max(99, `Dam name ${validation.MAX_CHAR_FUN(99)}`),

    breed_id: Yup.string().when('add_new_breed', {
      is: (value) => !value,
      then: () => Yup.string().required(`Breed name ${validation.REQUIRED}`),
      otherwise: () => Yup.string().notRequired(),
    }),
    breed_name: Yup.string().when('add_new_breed', {
      is: (value) => value,
      then: () =>
        Yup.string()
          .required(`Breed name ${validation.REQUIRED}`)
          .trim()
          .max(99, `Breed name ${validation.MAX_CHAR_FUN(99)}`),
      otherwise: () => Yup.string().notRequired(),
    }),

    variety_id: Yup.string().when(
      ['variety_seq', 'add_new_variety'],
      ([variety_seq, add_new_variety]) => {
        if (variety_seq && !add_new_variety) {
          return Yup.string().notRequired()
          // Yup.string().required(`Variety name ${validation.REQUIRED}`)
        }

        return Yup.string().notRequired()
      }
    ),
    variety_name: Yup.string().when(
      ['variety_seq', 'add_new_variety'],
      ([variety_seq, add_new_variety]) => {
        if (variety_seq && add_new_variety) {
          return Yup.string()
            .required(`Variety name ${validation.REQUIRED}`)
            .max(99, `Variety name ${validation.MAX_CHAR_FUN(99)}`)
        }

        return Yup.string().notRequired()
      }
    ),
    group_id: Yup.string().when(
      ['group_seq', 'add_new_group'],
      ([group_seq, add_new_group]) => {
        if (group_seq && !add_new_group) {
          return Yup.string().notRequired().trim()
          // Yup.string().required(`Group name ${validation.REQUIRED}`)
        }

        return Yup.string().notRequired()
      }
    ),
    group_name: Yup.string().when(
      ['group_seq', 'add_new_group'],
      ([variety_seq, add_new_group]) => {
        if (variety_seq && add_new_group) {
          return (
            Yup.string()
              .notRequired()
              // .required(`Group name ${validation.REQUIRED}`)
              .trim()
              .max(99, `Variety name ${validation.MAX_CHAR_FUN(99)}`)
          )
        }
        return Yup.string().notRequired()
      }
    ),
    gender: Yup.string().required(`Gender ${validation.REQUIRED}`),
    weight: Yup.number()
      .positive(validation.POSITIVE_NUMBER_ALLOWED)
      .typeError(validation.NUMBER_INVALID)
      .nullable()
      // .required(`Weight ${validation.REQUIRED}`)
      .min(0.1, `Weight ${validation.MIN_NUMBER(0.1)}`)
      .max(999, `Weight ${validation.MAX_NUMBER(999)}`)
      .transform((value) => {
        return value ? value : null
      }),
    registration_number: Yup.string().nullable().notRequired(),
    grand_championship: Yup.number()
      .positive(validation.POSITIVE_NUMBER_ALLOWED)
      .typeError(validation.NUMBER_INVALID)
      .notRequired()
      .nullable()
      // .required(`Grand Champion ${validation.REQUIRED}`)
      .min(1, `Grand Champion ${validation.MIN_NUMBER(1)}`)
      .max(9999999, `Grand Champion ${validation.MAX_NUMBER(9999999)}`)
      .transform((value) => {
        return value ? value : null
      }),
    winnings: Yup.string()
      .notRequired()
      .nullable()
      .max(299, `Grand Champion ${validation.MAX_CHAR_FUN(299)}`),
    add_from_existing_pedigree: Yup.boolean(),
    color_name: Yup.string().notRequired(),
  })

  const updateRabbitApi = (body) => {
    return new Promise((resolve, reject) => {
      dispatch(
        updateRabbit(body, (err, data) => {
          if (err) {
            reject(err)
          } else {
            resolve(data)
          }
        })
      )
    })
  }

  const onSubmit = async (values, formProps) => {
    const castValue = validationSchema.cast(values)
    formProps.setStatus(true)
    try {
      const formData = new FormData()
      formData.append('ear_no', castValue?.ear_no)
      formData.append('animal_name', castValue?.animal_name || '')
      formData.append('breed_name', castValue?.breed_name || '')
      formData.append('group_name', castValue?.group_name || '')
      formData.append('variety_name', castValue?.variety_name || '')
      formData.append('gender', castValue?.gender || '')
      formData.append('weight', castValue?.weight || 0)
      formData.append(
        'registration_number',
        castValue?.registration_number || ''
      )
      formData.append('grand_championship', castValue?.grand_championship || '')
      formData.append('winnings', castValue?.winnings || '')
      formData.append('color_name', castValue?.color_name || '')
      if (!values.rabbit_id) {
        formData.append('is_pedigree', 0)
        formData.append('date_of_birth', 0)
      }

      if (castValue?.img1) {
        formData.append('image', castValue.img1)
      }

      let res
      if (!values.rabbit_id) {
        // res = await addRabbitApi(formData)
      } else {
        const data = {
          params: {
            rabbit_id: values.rabbit_id,
          },
          data: formData,
        }
        res = await updateRabbitApi(data)
      }
      //

      if (res) {
        toast.success(res?.data)

        onCloseModal()
        setReload(!reload)
        setIsReloadComponentCall(true)
        // const data = {
        //   params: {
        //     _page: page,
        //     _limit: LIMIT,
        //     _sort: 'animal_name',
        //     _order: 'ASC',
        //   },
        // }

        // dispatch(ancestorList(data))
      }
    } catch (err) {
      formProps.setStatus(false)
      if (Array.isArray(err)) {
        toast.error(_.get(_.first(err), 'msg'))
      } else if (err.response.data) {
        toast.error(err.response.data)
      } else if (err) {
        toast.error(err)
      }
    }
  }
  return (
    <Formik
      initialValues={form}
      onSubmit={onSubmit}
      enableReinitialize
      validationSchema={validationSchema}
    >
      {({ values, status, errors }) => {
        return (
          <Form className='needs-validation club-selection'>
            <Row className='mt-3'>
              <Col sm='12' md='12'>
                <div className='mb-3'>
                  <Label className='form-label' htmlFor='ear_no'>
                    Ear No *
                  </Label>
                  <Field
                    name='ear_no'
                    disabled={disabled}
                    placeholder='Ear No'
                    type='text'
                    id='ear_no'
                    component={TextField}
                  />
                </div>
              </Col>
              <Col sm='12' md='12'>
                <div className='mb-3'>
                  <Label className='form-label' htmlFor='animal_name'>
                    {values.gender === 'M' ? 'Sire' : 'Dam'} Name *
                  </Label>
                  <Field
                    name='animal_name'
                    placeholder={` ${
                      values.gender === 'M' ? 'Sire' : 'Dam'
                    } Name`}
                    type='text'
                    id='animal_name'
                    component={TextField}
                    disabled={disabled}
                  />
                </div>
              </Col>
              <BreedSectionForm values={values} md={12} disabled={disabled} />
              <Col sm='12' md='12'>
                <div className='mb-3'>
                  <Label className='form-label' htmlFor='color_name'>
                    Color
                  </Label>
                  <div>
                    <Field
                      name='color_name'
                      type='text'
                      id='color_name'
                      placeholder='Color'
                      className='form-control'
                      component={TextField}
                      disabled={disabled}
                    />
                  </div>
                </div>
              </Col>
              <Col sm='12' md='12'>
                <div className='mb-3'>
                  <Label className='form-label' htmlFor='weight'>
                    Weight (Lbs.)
                  </Label>
                  <div>
                    <Field
                      name='weight'
                      type='number'
                      id='weight'
                      placeholder='Weight'
                      className='form-control'
                      component={TextField}
                      disabled={disabled}
                    />
                  </div>
                </div>
              </Col>
              <Col sm='12' md='12'>
                <div className='mb-3'>
                  <Label className='form-label' htmlFor='registration_number'>
                    Reg
                  </Label>
                  <div>
                    <Field
                      name='registration_number'
                      type='text'
                      id='registration_number'
                      placeholder='Reg'
                      className='form-control'
                      component={TextField}
                      disabled={disabled}
                    />
                  </div>
                </div>
              </Col>
              <Col sm='12' md='12'>
                <div className='mb-3'>
                  <Label className='form-label' htmlFor='grand_championship'>
                    Grand Champion #
                  </Label>
                  <div>
                    <Field
                      name='grand_championship'
                      type='number'
                      placeholder='Grand Champion #'
                      id='grand_championship'
                      className='form-control'
                      component={TextField}
                      disabled={disabled}
                    />
                  </div>
                </div>
              </Col>
              <Col sm='12' md='12'>
                <div className='mb-3'>
                  <Label className='form-label' htmlFor='winnings'>
                    Winnings
                  </Label>
                  <div>
                    <Field
                      name='winnings'
                      type='textarea'
                      id='winnings'
                      placeholder='Winnings'
                      className='form-control'
                      component={TextField}
                      disabled={disabled}
                    />
                  </div>
                </div>
              </Col>
              {!disabled ? (
                <Col md='12' className='text-center mb-3 dis-btn-caps'>
                  <Button type='submit' disabled={status} color='primary'>
                    SUBMIT
                  </Button>
                </Col>
              ) : (
                <Col md='12' className='text-center mb-3'>
                  <Button
                    color='warning'
                    type='button'
                    onClick={() => onCloseModal()}
                  >
                    CLOSE
                  </Button>
                </Col>
              )}
            </Row>
          </Form>
        )
      }}
    </Formik>
  )
}

export default RabbitForm
