import {
  SET_MODAL,
  PAYMENT_CREATE_INTENT,
  PAYMENT_CREATE_INTENT_DATA,
  PAYMENT_SUCCESS,
  PAYMENT_SUCCESS_DATA,
  PURCHASED_PREMIER_LIST,
  PURCHASED_PREMIER_LIST_SUCCESS,
  PREMIER_USER_SHOPPING_CART_RESET,
} from './actionTypes'

export const setPremiumModal = (data) => {
  return {
    type: SET_MODAL,
    payload: data,
  }
}

export const paymentIntentAction = (data) => {
  return {
    type: PAYMENT_CREATE_INTENT,
    payload: data,
  }
}
export const paymentIntentSuccess = (data) => {
  return {
    type: PAYMENT_CREATE_INTENT_DATA,
    payload: data,
  }
}

export const paymentSuccess = (data) => {
  return {
    type: PAYMENT_SUCCESS,
    payload: data,
  }
}
export const paymentSuccessData = (data) => {
  return {
    type: PAYMENT_SUCCESS_DATA,
    payload: data,
  }
}

export const purchasedPremierPlanList = (data) => {
  return {
    type: PURCHASED_PREMIER_LIST,
    payload: data,
  }
}
export const purchasedPremierPlanListSuccess = (data) => {
  return {
    type: PURCHASED_PREMIER_LIST_SUCCESS,
    payload: data,
  }
}

export const premierUserResetCardDetail = () => {
  return {
    type: PREMIER_USER_SHOPPING_CART_RESET,
  }
}
