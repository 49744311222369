import React, { useEffect, useState } from 'react'
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap'
import * as validation from '../../../helpers/validation'
import * as Util from '../../../helpers/utils'

import ImageCopper from '../../../components/ImageCropper/ImageCopper'
import {
  JudgeCreate,
  JudgeUpdate,
  MileList,
  allCountryList,
  allStateList,
} from '../../../store/actions'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { UrlEncodeHook } from '../../../hooks'
import { del, get } from '../../../helpers/api_helper'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import _ from 'lodash'

//
const JudgeForm = () => {
  const { urlDecode } = UrlEncodeHook()
  let { ID } = useParams()
  ID = urlDecode(ID)
  const history = useHistory()
  const dispatch = useDispatch()
  const { MileListing } = useSelector((state) => state.EasyFiedsMiles)
  const { easyFiedsUser } = useSelector((state) => state.EasyfidesProfile)

  //
  const [name, setName] = useState('')
  const [profile, setProfile] = useState('')
  const [description, setDescription] = useState('')
  const [willing_to_travel, setWilling_to_travel] = useState('')
  const [cavy_judge, setCavy_judge] = useState(0)
  const [rabbit_judge, setRabbit_judge] = useState(0)
  const [serve_as_judge, setServe_as_judge] = useState(0)
  const [serve_as_registrar, setServe_as_registrar] = useState(0)
  const [document_file, setDocument_file] = useState('')
  const [errors, setErrors] = useState({})
  //

  const [croppedImage, setCroppedImage] = useState(null)
  //
  const [country_id, setCountry_id] = useState('')
  const [city, setCity] = useState('')
  const [state_id, setState_id] = useState('')

  //

  const [selectedFiles, setselectedFiles] = useState(null)
  const [errorProfile, setErrorProfile] = useState('')
  const [showModal, setShowModal] = useState(false)

  const { CountryList } = useSelector((state) => state.Country)
  const { StateList } = useSelector((state) => state.State)
  //

  useEffect(() => {
    dispatch(MileList())
    dispatch(allCountryList())
  }, [])
  useEffect(() => {
    if (country_id) {
      let params = {
        country_id: country_id,
      }
      dispatch(allStateList(params))
    }
  }, [country_id])

  useEffect(() => {
    if (ID) {
      get(`/api/v1/admin/easyfied/sales-services-judge/one/${ID}`)
        .then((res) => {
          const { data } = res
          setDocument_file(data?.image_file || '')
          setName(data?.display_name || '')
          setDescription(data?.description || '')
          setRabbit_judge(data?.rabbit_judge || 0)
          setCavy_judge(data?.cavy_judge || 0)
          setServe_as_judge(data?.serve_as_judge || 0)
          setServe_as_registrar(data?.serve_as_registrar | 0)
          setWilling_to_travel(data?.travel_radius_id || '')
          setCity(data?.city)
          setCountry_id(data?.country_id)
          setState_id(data?.state_id)
        })
        .catch((err) => {
          if (err?.response?.data) {
            toast.error(err?.response?.data)
          }
        })
    } else {
      let address = _.first(easyFiedsUser?.address)
      setCity(address?.city)
      setCountry_id(address?.country_id)
      setState_id(address?.state_id)
    }
  }, [ID, easyFiedsUser?.address])

  //
  const onSubmit = () => {
    let validationFlag = false
    let err = {}
    if (!country_id) {
      validationFlag = true
      err.country_id = `Country ${validation.REQUIRED}`
    }
    if (!state_id) {
      validationFlag = true
      err.state_id = `State ${validation.REQUIRED}`
    }
    if (!city?.trim()) {
      validationFlag = true
      err.city = `City ${validation.REQUIRED}`
    }
    if (city.trim()) {
      if (city?.trim().length >= 50) {
        validationFlag = true
        err.city = `City ${validation.MAX_CHAR_FUN(50)}`
      }
    }
    if (!serve_as_judge && !serve_as_registrar) {
      validationFlag = true
      err.service_as = `Service as ${validation.REQUIRED}`
    }
    if (!rabbit_judge && !cavy_judge) {
      validationFlag = true
      err.breed = `Breed  ${validation.REQUIRED}`
    }
    if (!name.trim()) {
      validationFlag = true
      err.name = `Name ${validation.REQUIRED}`
    }
    // if (!profile && !ID) {
    //   validationFlag = true
    //   setErrorProfile(`Image ${validation.REQUIRED}`)
    // }
    if (name.trim()) {
      if (!Util.ONLY_CHAR_REGEX.test(name.trim())) {
        validationFlag = true
        err.name = `Name ${validation.ONLY_CHARACTER_AND_HYPHEN}`
      } else if (name.trim().length >= 50) {
        validationFlag = true
        err.name = `Name ${validation.MAX_CHAR}`
      }
    }

    if (!description.trim()) {
      validationFlag = true
      err.description = `Description ${validation.REQUIRED}`
    }

    if (description.trim()) {
      if (description.trim().length >= 2000) {
        validationFlag = true
        err.description = `Description ${validation.MAX_CHAR_FUN(2000)}`
      }
    }
    if (!willing_to_travel) {
      validationFlag = true
      err.willing_to_travel = `Willing to Travel  ${validation.REQUIRED}`
    }
    setErrors(err)
    if (!validationFlag && !errorProfile) {
      //
      document.getElementById('submit-btn').setAttribute('disabled', true)
      let formData = new FormData()
      formData.append('display_name', name.trim())
      formData.append('description', description.trim())
      formData.append('travel_radius_id', willing_to_travel)
      formData.append('serve_as_judge', serve_as_judge)
      formData.append('serve_as_registrar', serve_as_registrar)
      if (profile) {
        formData.append('document_file', profile)
      }
      formData.append('rabbit_judge', rabbit_judge)
      formData.append('cavy_judge', cavy_judge)
      formData.append('country_id', country_id)
      formData.append('state_id', state_id)
      formData.append('city', city?.trim())

      let obj = {
        ID,
        data: formData,
        history,
      }
      if (!ID) {
        dispatch(JudgeCreate(obj))
      } else {
        dispatch(JudgeUpdate(obj))
      }
    }
  }

  const changeFile = (e) => {
    setselectedFiles(null)
    setCroppedImage(null)
    let file = e.target.files[0]
    let fsize = file.size
    fsize = Math.round(fsize / 1024)

    if (!file) {
      setErrorProfile('Please select valid image')
    } else if (!file.name.match(/\.(jpg|jpeg|png|PNG|JPEG|JPG|)$/)) {
      setErrorProfile('Please select valid image')
    } else if (fsize >= 4096) {
      setErrorProfile('Please select less then 4 MB image')
    } else {
      setShowModal(true)
      setselectedFiles(URL.createObjectURL(file))
      setErrorProfile('')
    }
  }

  const onDeleteImage = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      width: '380px',
      showCancelButton: true,
      confirmButtonColor: '#062575',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        del(
          `/api/v1/admin/easyfied/sales-services-judge/delete-img/${ID}`
        ).then((res) => {
          if (res?.data) {
            toast.success(res?.data)
            setProfile('')
            setDocument_file('')
          }
          // console.log('🚀 ~ file: JudgeForm.js:192 ~ del ~ res:', res)
        })
        // .catch((err) => {
        //   if (err?.response?.data) {
        //     toast.success(err?.response?.data)
        //   }
        // })
      }
    })
  }
  return (
    <div className='profile-content-area my-6 card card-body mb-10'>
      <div className='pb-6 easyfied-form'>
        <Row>
          <Col md={12} sm={12} lg={12}>
            <h4 className='mb-3'>Service as a Judge or Registrar</h4>
          </Col>
          <Col md={12} sm={12} lg={12}>
            <Label>Service as * </Label>
            <FormGroup className='mb-2'>
              <Input
                type='checkbox'
                name='register_as'
                value={'register_as_judge'}
                id='judge'
                onChange={() => setServe_as_judge(serve_as_judge === 1 ? 0 : 1)}
                checked={serve_as_judge}
                // checked={}
              />{' '}
              <Label htmlFor='judge' check>
                Judge
              </Label>
              <Input
                type='checkbox'
                name='service_as'
                value={'register_as'}
                id='registrar_id'
                onChange={() =>
                  setServe_as_registrar(serve_as_registrar === 1 ? 0 : 1)
                }
                checked={serve_as_registrar}
              />{' '}
              <Label htmlFor='registrar_id' check>
                Registrar
              </Label>
              {errors?.service_as ? (
                <div className='text-danger mt-1'>{errors?.service_as}</div>
              ) : null}
            </FormGroup>
          </Col>
          <Col md={12} sm={12} lg={12}>
            <Label>Breed* </Label>
            <FormGroup className='mb-2'>
              <Input
                type='checkbox'
                name='breed'
                id='Rabbit'
                onChange={() => setRabbit_judge(rabbit_judge === 1 ? 0 : 1)}
                checked={rabbit_judge}
                // checked={}
              />{' '}
              <Label htmlFor='Rabbit' check>
                Rabbit
              </Label>
              <Input
                type='checkbox'
                name='breed'
                onChange={() => setCavy_judge(cavy_judge === 1 ? 0 : 1)}
                checked={cavy_judge}
                id='cavy'
              />{' '}
              <Label htmlFor='cavy' check>
                Cavy
              </Label>
              {errors?.breed ? (
                <div className='text-danger mt-1'>{errors?.breed}</div>
              ) : null}
            </FormGroup>
          </Col>
          <FormGroup className='my-3'>
            <Label htmlFor='Name'>Name * </Label>
            <Input
              id='Name'
              name='name'
              placeholder='Name'
              type='text'
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            {errors?.name ? (
              <div className='text-danger mt-1'>{errors?.name}</div>
            ) : null}
          </FormGroup>

          <FormGroup className='my-3'>
            <Label className='d-block '>
              Image Upload
              <small className='text-muted'>
                {' '}
                ( Allows only png, jpg, jpeg formats )
              </small>
            </Label>
            <Input
              name='name'
              className='img-box-bor'
              placeholder='Name'
              type='file'
              accept='image/png, image/jpg, image/jpeg'
              value={''}
              style={{ color: 'transparent' }}
              onChange={changeFile}
            />
            {document_file && !profile ? (
              <div className='mt-4'>
                <div>
                  <img
                    src={document_file}
                    alt='profile'
                    style={{
                      width: 200,
                      height: 150,
                    }}
                  />
                  <div>
                    <Button
                      onClick={() => onDeleteImage()}
                      // onClick={ClearLeftImage}
                      className='mt-1'
                      variant='contained'
                      color='danger'
                    >
                      Delete
                    </Button>
                  </div>
                </div>
              </div>
            ) : null}

            {errorProfile && <div className='text-danger'>{errorProfile}</div>}
          </FormGroup>

          <FormGroup className='my-3'>
            <Label htmlFor='Description' className='d-block'>
              Description *
            </Label>
            <Input
              id='Description'
              name='Description'
              placeholder='Description'
              type='textarea'
              rows='4'
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            {errors?.description ? (
              <div className='text-danger mt-1'>{errors?.description}</div>
            ) : null}
          </FormGroup>
          <Label>Willing to Travel ( In Miles ) * </Label>
          <FormGroup value={willing_to_travel} name='willing_to_travel'>
            {Array.isArray(MileListing) && MileListing.length
              ? MileListing.map((miles) => {
                  return (
                    <>
                      <Input
                        type='radio'
                        value={miles?.travel_radius_id || ''}
                        name='willing_to_travel'
                        id={`${miles?.title}-${miles?.travel_radius_id}`}
                        checked={willing_to_travel === miles?.travel_radius_id}
                        onChange={() => {
                          setWilling_to_travel(miles?.travel_radius_id)
                        }}
                      />
                      <Label
                        check
                        htmlFor={`${miles?.title}-${miles?.travel_radius_id}`}
                      >
                        {miles?.title}
                      </Label>
                    </>
                  )
                })
              : null}

            {errors?.willing_to_travel ? (
              <div className='text-danger mt-1'>
                {errors?.willing_to_travel}
              </div>
            ) : null}
          </FormGroup>

          <FormGroup className='my-3'>
            <Label htmlFor='country_id' className='d-block'>
              Country *
            </Label>
            <select
              name='country_id'
              className='form-control form-select'
              onChange={(e) => {
                setCountry_id(e.target.value)
                setState_id('')
              }}
              value={country_id}
            >
              <option value=''>Select Country</option>
              {Array.isArray(CountryList) && CountryList.length
                ? CountryList.map((country, i) => {
                    return (
                      <option value={country?.country_id || ''} key={i}>
                        {' '}
                        {country?.country_name || ''}
                      </option>
                    )
                  })
                : null}
            </select>
            {errors?.country_id ? (
              <div className='text-danger mt-1'>{errors?.country_id}</div>
            ) : null}
          </FormGroup>
          <FormGroup className='my-3'>
            <Label htmlFor='state_id' className='d-block'>
              State *
            </Label>
            <select
              name='state_id'
              className='form-control form-select'
              onChange={(e) => setState_id(e.target.value)}
              value={state_id}
              disabled={!country_id}
            >
              <option value=''>Select State</option>
              {Array.isArray(StateList) && StateList.length
                ? StateList.map((state, i) => {
                    return (
                      <option value={state?.state_id || ''} key={i}>
                        {' '}
                        {state?.state_code || ''}
                      </option>
                    )
                  })
                : null}
            </select>
            {errors?.state_id ? (
              <div className='text-danger mt-1'>{errors?.state_id}</div>
            ) : null}
          </FormGroup>
          <FormGroup className='my-3'>
            <Label htmlFor='city'>City *</Label>
            <Input
              id='city'
              name='city'
              placeholder='City'
              type='text'
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
            {errors?.city ? (
              <div className='text-danger mt-1'>{errors?.city}</div>
            ) : null}
          </FormGroup>
          <div className='p-20px-t mt-6'>
            <Button
              type='submit'
              name='btnSubmit'
              id={'submit-btn'}
              className='w-lg waves-effect waves-light btn btn-success'
              onClick={onSubmit}
              title='Sunmit'
            >
              SUBMIT
            </Button>
          </div>

          <Modal isOpen={showModal} className='bg-white'>
            <ModalHeader>Image Cropper</ModalHeader>
            <ModalBody>
              <ImageCopper
                selectedFiles={selectedFiles}
                setCroppedImage={setCroppedImage}
                setProfile={setProfile}
                croppedImage={croppedImage}
              />
            </ModalBody>
            <ModalFooter>
              <Button color='warning' onClick={() => setShowModal(false)}>
                Close
              </Button>
            </ModalFooter>
          </Modal>
        </Row>
      </div>
    </div>
  )
}

export default JudgeForm
