import React, { useEffect, useState } from 'react'
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap'
import * as Util from '../../../helpers/utils'
import { useDispatch, useSelector } from 'react-redux'
import {
  EquipmentMediaListing,
  addMoreMediaEquipment,
  deleteMediaEquipment,
  saveEquipmentMedia,
  updateEquipmentMedia,
} from '../../../store/actions'
import * as validation from '../../../helpers/validation'
import ImageCopper from '../../../components/ImageCropper/ImageCopper'
import Swal from 'sweetalert2'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import { UrlEncodeHook } from '../../../hooks'
const AnimalImage = () => {
  let { ID } = useParams()
  const { urlDecode } = UrlEncodeHook()
  ID = urlDecode(ID)
  const dispatch = useDispatch()
  const { EquipmentMedia } = useSelector((state) => state.Equipment)
  const [List, setList] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [activeIndex, setActiveIndex] = useState()
  const [reload, setReload] = useState(false)
  useEffect(() => {
    if (EquipmentMedia.length) {
      setList(EquipmentMedia)
    }
  }, [EquipmentMedia])
  const onChange = (e, index) => {
    const { value, name } = e.target
    let list = [...List]
    list[index][name] = value
    setList(list)
  }

  useEffect(() => {
    const body = {
      params: {
        equipment_product_id: ID,
        doc_type: 'img',
      },
    }
    dispatch(EquipmentMediaListing(body))
  }, [reload])
  return (
    <div className='profile-content-area my-6 card card-body mb-10'>
      <div className='pb-6 easyfied-form'>
        <Row>
          <Col md={12} sm={12} lg={12}>
            <h4 className='mb-3'>Equipment Image</h4>
          </Col>
          {Array.isArray(List) && List.length
            ? List.map((animal, i) => {
                return (
                  <DocForm
                    key={i}
                    values={animal}
                    index={i}
                    onChange={onChange}
                    showModal={showModal}
                    setShowModal={setShowModal}
                    setActiveIndex={setActiveIndex}
                    activeIndex={activeIndex}
                    setReload={setReload}
                    reload={reload}
                  />
                )
              })
            : null}
          {/* <div className='p-20px-t mt-6 text-center'>
            <Button
              name='btnSubmit'
              className='w-lg waves-effect waves-light btn btn-success'
              title='Add More'
              disabled={!_.last(List)?.equipment_product_document_id}
              onClick={() => dispatch(addMoreMediaEquipment())}
            >
              + ADD MORE
            </Button>
          </div> */}
        </Row>
      </div>
    </div>
  )
}

export default AnimalImage

const DocForm = (props) => {
  const dispatch = useDispatch()
  let { ID } = useParams()
  const { urlDecode } = UrlEncodeHook()
  ID = urlDecode(ID)
  const {
    values,
    index,
    setShowModal,
    showModal,
    setActiveIndex,
    activeIndex,
    setReload,
    reload,
  } = props

  const [errors, setErrors] = useState({})
  const [croppedImage, setCroppedImage] = useState(null)

  const [selectedFiles, setselectedFiles] = useState(null)
  const [errorProfile, setErrorProfile] = useState('')

  const onSave = () => {
    let validationFlag = false
    let err = {}
    if (!values?.document_title.trim()) {
      validationFlag = true
      err.title = `Title ${validation.REQUIRED}`
    }
    if (values?.document_title.trim()) {
      if (values?.document_title.trim().length > 100) {
        validationFlag = true
        err.title = `Title ${validation.MAX_CHAR_FUN(100)}`
      } else if (
        !Util.ONLY_ALLOW_NUMBER_AND_CHAR.test(values?.document_title.trim())
      ) {
        validationFlag = true
        err.title = `${validation.INVALID_DATA}`
      }
    }
    // if (!values?.display_order) {
    //   validationFlag = true
    //   err.display_order = `Display order ${validation.REQUIRED}`
    // } else if (values?.display_order) {
    //   if (!Util.ONLY_NUMBER_REGEX.test(values?.display_order)) {
    //     validationFlag = true
    //     err.display_order = `${validation.INVALID_DATA}`
    //   } else if (Number(values?.display_order) < 1) {
    //     validationFlag = true
    //     err.display_order = `Display order ${validation.MIN_NUMBER(1)}`
    //   } else if (Number(values?.display_order) > 999) {
    //     validationFlag = true
    //     err.display_order = `Display order ${validation.MAX_NUMBER(999)}`
    //   }
    // }

    if (!values.new_document_file && !values?.equipment_product_document_id) {
      validationFlag = true
      setErrorProfile(`Image ${validation.REQUIRED}`)
    }

    setErrors(err)
    if (!validationFlag && !errorProfile) {
      document
        .getElementById(`submit-btn-${index}`)
        .setAttribute('disabled', true)
      const fb = new FormData()

      fb.append('display_order', values?.display_order)
      fb.append('document_title', values?.document_title.trim())
      fb.append('doc_type', 'img')
      if (values.new_document_file) {
        fb.append('document_file', values.new_document_file)
      }
      const body = {
        params: {
          ID: values?.equipment_product_document_id || null,
          equipment_product_id: ID,
        },
        data: fb,
        setReload: setReload,
        reload: reload,
        index,
      }
      if (values?.equipment_product_document_id) {
        dispatch(updateEquipmentMedia(body))
      } else {
        dispatch(saveEquipmentMedia(body))
      }
    }
  }
  const changeFile = (e) => {
    setActiveIndex(index)
    setselectedFiles(null)
    setCroppedImage(null)
    let file = e.target.files[0]
    let fsize = file.size
    fsize = Math.round(fsize / 1024)

    if (!file) {
      setErrorProfile('Please select valid image')
    } else if (!file.name.match(/\.(jpg|jpeg|png|PNG|JPEG|JPG|)$/)) {
      setErrorProfile('Please select valid image')
    } else if (fsize >= 4096) {
      setErrorProfile('Please select less then 4 MB image')
    } else {
      setShowModal(true)
      setselectedFiles(URL.createObjectURL(file))
      setErrorProfile('')
    }
  }
  const setCropFile = (file) => {
    let obj = {
      target: {
        name: 'new_document_file',
        value: file,
      },
    }
    props.onChange(obj, index)
  }

  const mediaDelete = async (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      width: '380px',
      showCancelButton: true,
      confirmButtonColor: '#062575',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        let obj = {
          ID: values?.equipment_product_document_id,
          index,
          setReload: setReload,
          reload: reload,
        }
        dispatch(deleteMediaEquipment(obj))
      }
    })
  }

  return (
    <React.Fragment key={props.i}>
      <Row>
        <Col md={3} sm={3} lg={3}>
          <FormGroup className='my-3'>
            <Label className='d-block'>Image Upload *</Label>
            <Input
              name='name'
              placeholder='Name'
              type='file'
              className='img-box-bor'
              accept='image/png, image/jpg, image/jpeg'
              onChange={changeFile}
              value={''}
              style={{ color: 'transparent' }}
            />
            {errorProfile ? (
              <div className='text-danger mt-1'>{errorProfile}</div>
            ) : null}
            <small>( Allows only png, jpg, jpeg formats )</small>
          </FormGroup>
        </Col>
        {/* <Col md={3} sm={3} lg={3}>
          <FormGroup className='my-3'>
            <Label htmlFor='amount'> Display Order *</Label>
            <Input
              id='display_order'
              name='display_order'
              placeholder='Display Order'
              type='text'
              value={values?.display_order || ''}
              onChange={(e) => props.onChange(e, index)}
            />
            {errors?.display_order ? (
              <div className='text-danger mt-1'>{errors?.display_order}</div>
            ) : null}
          </FormGroup>
        </Col> */}
        <Col md={3} sm={3} lg={3}>
          <FormGroup className='my-3'>
            <Label htmlFor='file_title'> Image Title *</Label>
            <Input
              id='file_title'
              name='document_title'
              placeholder='Image Title'
              type='text'
              value={values?.document_title || ''}
              onChange={(e) => props.onChange(e, index)}
            />
            {errors?.title ? (
              <div className='text-danger mt-1'>{errors?.title}</div>
            ) : null}
          </FormGroup>
        </Col>
        <Col md={3} sm={3} lg={3} className='mt-2 text-center'>
          {/* <Link to={`/easyfides/contact/1`}> */}
          <Button
            size='sm'
            color='primary'
            className='me-2 pad-pmr mb-2'
            title='View'
            onClick={() => {
              if (values?.document_file) {
                window.open(values?.document_file, '_blank')
              }
            }}
            disabled={!values?.equipment_product_document_id}
          >
            <i className='fas fa-eye'></i>
          </Button>
          {/* </Link> */}

          <Button
            size='sm'
            color='danger'
            className='me-2 mb-2'
            title='Delete'
            disabled={!values?.document_title}
            onClick={() => mediaDelete()}
          >
            <i className='fas fa-trash'></i>
          </Button>
          {/* <Link
            to='/easyfides/profile/my_animal/animal_image'
            className='me-2'
            title='save'
          > */}
          <Button
            className='img-btn-save mb-2'
            onClick={onSave}
            id={`submit-btn-${index}`}
            title="Save"
          >
            {' '}
            SAVE{' '}
          </Button>
          {/* </Link> */}
        </Col>
      </Row>
      {activeIndex === index ? (
        <Modal isOpen={showModal} className='bg-white'>
          <ModalHeader>Image Cropper</ModalHeader>
          <ModalBody>
            <ImageCopper
              selectedFiles={selectedFiles}
              setCroppedImage={setCroppedImage}
              setProfile={setCropFile}
              croppedImage={croppedImage}
            />
          </ModalBody>
          <ModalFooter>
            <Button color='warning' onClick={() => setShowModal(false)}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      ) : null}
    </React.Fragment>
  )
}
