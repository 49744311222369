import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { Table } from 'react-super-responsive-table'
import { Col, Container, Row } from 'reactstrap'
import Swal from 'sweetalert2'
import { del, get } from '../../../helpers/api_helper'
import { getDate, getTime } from '../../../helpers/date_helper'
import { setMsg, setShowAlert } from '../../../store/successpopup/actions'
import SuccessPopup from '../../CommonComponent/successPopup1'
//Import
import filterImg from '../../../assets/images/filter.png'

const SubUserList = () => {
  const [subUsers, setSubUsers] = useState([])
  const [sort, setSort] = useState(1)
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.Login)

  const history = useHistory()
  let deleteUser = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      width: '380px',
      showCancelButton: true,
      confirmButtonColor: '#062575',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        del(`/api/v1/subuser/${id}`)
          .then((response) => {
            dispatch(setShowAlert(true))
            dispatch(setMsg(response))
            setTimeout(() => {
              dispatch(setShowAlert(false))
              dispatch(setMsg(''))
            }, 1600)
            get(`/api/v1/subuser`)
              .then((data) => {
                console.log(data)
                setSubUsers(data?.data || [])
              })
              .catch((error) => {
                console.log(error)
              })
          })
          .catch((error) => {
            console.log(error)
          })
      }
    })
  }
  useEffect(() => {
    get(`/api/v1/subuser`)
      .then((data) => {
        setSubUsers(data?.data || [])
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])
  useEffect(() => {
    let Data
    if (sort === 1) {
      Data = _.orderBy(subUsers, (i) => i.created_on, 'desc')
    } else {
      Data = _.orderBy(subUsers, (i) => i.created_on, 'asc')
    }

    setSubUsers(Data || [])
  }, [sort])
  return (
    <React.Fragment>
      <div className='page-content bg-gray-101 min-h-80'>
        <section id='about' className='section1 pages'>
          <Container fluid={true} className='auto-fit'>
            <Col lg={12}>
              <Row>
                <Col lg={10} className='text-center'>
                  <div className='page-title-box justify-content-between'>
                    <h2 className='top-title mb-4'>
                      <span>SUB USER LIST</span>
                    </h2>
                  </div>
                </Col>
                {(subUsers?.length < 10 ||
                  !subUsers ||
                  user?.id ===
                    Number(process.env.REACT_APP_HIGHLIGHT_USER)) && (
                  <Col lg={2} className='text-right mtb-8'>
                    <Link
                      to='/add-subuser'
                      className='btn btn-info btn btn-info create-btn mb-4'
                      title='Create'
                    >
                      CREATE
                    </Link>
                  </Col>
                )}
              </Row>
            </Col>
            <Row>
              <Col lg={12}>
                <div className='table-responsive text-center'>
                  <Table className='table mb-0 cust-tab'>
                    <thead className='table-color'>
                      <tr>
                        <th>No.</th>
                        <th className='w-break'>Name</th>
                        <th>Status</th>
                        <th
                          onClick={() => setSort(sort === 1 ? 2 : 1)}
                          style={{ cursor: 'pointer' }}
                        >
                          Registered On
                          <img src={filterImg} alt='Sort' className='p-r-3' />
                        </th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {subUsers.map((subUser, index) => {
                        return (
                          <tr>
                            <td
                              className={`${
                                Number(subUser.active) === 0
                                  ? 'inactive-user'
                                  : ''
                              }`}
                            >
                              {index + 1}
                            </td>
                            <td
                              className={`${
                                Number(subUser.active) === 0
                                  ? 'inactive-user'
                                  : ''
                              }`}
                            >
                              {subUser.last_name} {subUser.first_name}
                            </td>
                            <td
                              className={`${
                                Number(subUser.active) === 0
                                  ? 'inactive-user text-red-view'
                                  : ''
                              }`}
                            >
                              {Number(subUser.active) === 0
                                ? 'In Active'
                                : 'Active'}
                            </td>
                            <td
                              className={`${
                                Number(subUser.active) === 0
                                  ? 'inactive-user'
                                  : ''
                              }`}
                            >
                              {getDate(subUser.created_on) +
                                ' ' +
                                getTime(subUser.created_on)}
                            </td>
                            <td>
                              <img
                                src={`${process.env.PUBLIC_URL}/assets/img/icons/update.png`}
                                onClick={() => {
                                  history.push({
                                    pathname: `/edit-subuser`,
                                    state: {
                                      first_name: subUser.first_name,
                                      last_name: subUser.last_name,
                                      active: subUser.active,
                                      arba_number: subUser.arba_number,
                                      id: subUser.id,
                                    },
                                  })
                                }}
                                alt='update'
                                title='Update'
                                className='p-r-3 pe-auto mb-1'
                              />
                              <img
                                src={`${process.env.PUBLIC_URL}/assets/img/icons/delete.png`}
                                onClick={() => {
                                  deleteUser(subUser.id)
                                }}
                                alt='delete'
                                title='Delete'
                                className='p-r-3 pe-auto mb-1'
                              />
                            </td>
                          </tr>
                        )
                      })}
                      {(!subUsers || subUsers.length === 0) && (
                        <tr>
                          <td colSpan='5'>No Data Found</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <SuccessPopup />
      </div>
    </React.Fragment>
  )
}

export default SubUserList
