import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
  Spinner,
  Table,
} from 'reactstrap';
import Collapse from '@mui/material/Collapse';
import { Link } from 'react-router-dom';
import {
  addAnimalListing,
  allClassTypeList,
  allStateList,
  listingAnimal,
  pageChange,
} from '../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { get } from '../helpers/api_helper';
import _ from 'lodash';
import Pagination from './Profile/Pagination/Pagination';
import { UrlEncodeHook } from '../hooks';
import Logo from '../assets/images/logo.png';
import { toast } from 'react-toastify';

import ImageModal from '../components/ImageModal/ImageModal';
const AnimalListing = (props) => {
  const { urlEncode } = UrlEncodeHook();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(null);
  const [modal, setModal] = useState(false);
  const [imageList, setImageList] = useState([]);
  const { BreedList } = useSelector((state) => state.Breeds);
  const { ClassTypeList } = useSelector((state) => state.ClassTypes);
  const { StateList } = useSelector((state) => state.State);

  const { page, perPage } = useSelector((state) => state.pagination);
  const { MyAnimalList, loading } = useSelector(
    (state) => state.EasyFiedsAnimal
  );
  //
  const [category_id, setCategory_id] = useState('');
  const [breed_id, setBreed_id] = useState('');
  const [class_type, setClass_type] = useState('');
  const [finalBreedList, setFinalBreedList] = useState([]);
  const [finalClassList, setFinalClassList] = useState([]);
  const [state_id, setState_id] = useState('');
  const [city, setCity] = useState('');
  const [cityList, setCityList] = useState([]);
  const [StatesList, setStatesList] = useState([]);

  //
  const [BreedCategory, setBreedCategory] = useState([]);

  useEffect(() => {
    // if (category_id)
    let obj = { breed_category_id: category_id };
    dispatch(addAnimalListing(obj));
  }, [category_id]);
  useEffect(() => {
    dispatch(allClassTypeList());
    dispatch(allStateList());
  }, []);
  useEffect(() => {
    let params = {
      _limit: perPage,
      _page: 1,
      _sort: 'created_on',
      _order: 'DESC',
      category_id: category_id,
      breed_id: breed_id,
      class_type_id: class_type,
      all_user_data: 1,
      state_id,
      city: city,
    };
    dispatch(listingAnimal(params));
  }, [category_id, breed_id, class_type, state_id, city]);
  useEffect(() => {
    if (category_id && BreedList.length) {
      let params = {
        _sort: 'created_on',
        _order: 'DESC',
        category_id: category_id,
        breed_id: breed_id,
        class_type_id: class_type,
        all_user_data: 1,
      };
      get(
        `/api/v1/easyfied/animal-listing/user/all?_page=&_sort=${
          params?._sort || ''
        }&_order=${params?._order || ''}&category_id=${
          params?.category_id || ''
        }&breed_id=${params?.breed_id || ''}&class_type_id=${
          params?.class_type_id || ''
        }&all_user_data=${params?.all_user_data || ''}`
      ).then((res) => {
        const finalRes = res.data;

        let breedList = _.intersectionBy(
          BreedList,
          _.uniqBy(finalRes, 'breed_id'),
          'breed_id'
        );
        setFinalBreedList(breedList);
      });
    } else {
      setFinalBreedList([]);
    }
  }, [category_id, BreedList]);

  // class type list
  useEffect(() => {
    if (ClassTypeList.length) {
      let params = {
        _sort: 'created_on',
        _order: 'DESC',
        category_id: category_id,
        breed_id: breed_id,
        class_type_id: class_type,
        all_user_data: 1,
      };
      get(
        `/api/v1/easyfied/animal-listing/user/all?_page=&_sort=${
          params?._sort || ''
        }&_order=${params?._order || ''}&category_id=${
          params?.category_id || ''
        }&breed_id=${params?.breed_id || ''}&class_type_id=${
          params?.class_type_id || ''
        }&all_user_data=${params?.all_user_data || ''}`
      ).then((res) => {
        const finalRes = res.data;

        let classList = _.intersectionBy(
          _.map(ClassTypeList, (q) => {
            return {
              ...q,
              class_type_id: q.class_id,
            };
          }),
          _.uniqBy(finalRes, 'class_type_id'),
          'class_type_id'
        );

        setFinalClassList(classList);
      });
    } else {
      setFinalClassList([]);
    }
  }, [breed_id, category_id, ClassTypeList]);

  //
  useEffect(() => {
    let params = {
      _sort: 'created_on',
      _order: 'DESC',
      all_user_data: 1,
    };
    get(
      `/api/v1/easyfied/animal-listing/user/all?_page=&_sort=${
        params?._sort || ''
      }&_order=${params?._order || ''}&category_id=${
        params?.category_id || ''
      }&breed_id=${params?.breed_id || ''}&class_type_id=${
        params?.class_type_id || ''
      }&all_user_data=${params?.all_user_data || ''}`
    ).then((res) => {
      const finalRes = res.data;

      let categoryList = _.intersectionBy(
        Category,
        _.uniqBy(finalRes, 'category_id'),
        'category_id'
      );
      setBreedCategory(categoryList);

      // setFinalBreedList(breedList)
    });
  }, []);

  //
  useEffect(() => {
    if (Array.isArray(StateList) && StateList.length) {
      let params = {
        _sort: 'created_on',
        _order: 'DESC',
        category_id: category_id,
        breed_id: breed_id,
        class_type_id: class_type,
        all_user_data: 1,
      };
      get(
        `/api/v1/easyfied/animal-listing/user/all?_page=&_sort=${
          params?._sort || ''
        }&_order=${params?._order || ''}&category_id=${
          params?.category_id || ''
        }&breed_id=${params?.breed_id || ''}&class_type_id=${
          params?.class_type_id || ''
        }&all_user_data=${params?.all_user_data || ''}
        
        `
      ).then((res) => {
        const finalRes = res.data;

        let stateData = _.intersectionBy(
          StateList,
          _.uniqBy(finalRes, 'state_id'),
          'state_id'
        );
        setStatesList(stateData);
      });
    }
  }, [StateList, category_id, breed_id, class_type]);

  //
  useEffect(() => {
    let params = {
      _sort: 'created_on',
      _order: 'DESC',
      category_id: category_id,
      breed_id: breed_id,
      class_type_id: class_type,
      all_user_data: 1,
      state_id,
    };
    get(
      `/api/v1/easyfied/animal-listing/user/all?_page=&_sort=${
        params?._sort || ''
      }&_order=${params?._order || ''}&category_id=${
        params?.category_id || ''
      }&breed_id=${params?.breed_id || ''}&class_type_id=${
        params?.class_type_id || ''
      }&all_user_data=${params?.all_user_data || ''}&state_id=${
        params?.state_id || ''
      }
      
      `
    ).then((res) => {
      const finalRes = res.data;

      let cityData = _.map(_.uniqBy(finalRes, 'city'), (q) => ({
        city_name: q.city,
      }));

      setCityList(cityData);
    });
  }, [category_id, breed_id, class_type, state_id]);

  //
  const handleClick = (data) => {
    if (data === open) {
      setOpen(null);
    } else {
      setOpen(data);
    }
  };

  //
  const showModal = async (id) => {
    try {
      const res = await get(
        `/api/v1/easyfied/animal-listing-doc/user/all?animal_listing_id=${id}`
      );
      let finalData = res?.data || [];
      finalData = _.orderBy(finalData, 'display_order');
      finalData = Array.isArray(finalData)
        ? finalData.map((x) => ({
            original: x.document_file,
            thumbnail: x.document_file,
            description: x.image_title,
          }))
        : [];
      setImageList(finalData);
      setModal(true);
    } catch (err) {
      if (err?.response?.data) {
        toast.error(err?.response?.data);
      }
    }
  };

  const modalClose = () => {
    setModal(false);
    setImageList([]);
  };
  const paginationClick = (nextPage) => {
    const { selected } = nextPage;
    const finalPage = (Number(selected) || 0) + 1;
    let params = {
      _limit: perPage,
      _page: finalPage,
      _sort: 'created_on',
      _order: 'DESC',
      category_id: category_id,
      breed_id: breed_id,
      class_type_id: class_type,
      all_user_data: 1,
    };
    dispatch(listingAnimal(params));
  };
  return (
    <section className="section bg-gray-101 pages event-list mt-12">
      <Container fluid>
        <div class="row mb-6">
          <div class="col-md-12 col-lg-12 col-xs-12 mb-3">
            <marquee behavior="scroll" scrollamount="6" direction="left">
              <h4 className="text-danger">
                Welcome to{' '}
                <strong>
                  {' '}
                  <em>Easyfieds,</em>
                </strong>{' '}
                this is currently a free service. View the How 2's for more
                information on how to use{' '}
                <strong>
                  {' '}
                  <em>Easyfieds.</em>
                </strong>
              </h4>
            </marquee>
          </div>
          <div class="col-md-12 col-lg-12 col-xs-12 text-center">
            <h2 className="top-title">
              <span>Rabbits & Cavies</span>
            </h2>
          </div>
        </div>

        {/* <Card className='mb-5'>
          <CardHeader>
            <Col lg="12" className="text-center filter-line win-gift">
              <h4 className='mt-1 text-red'>Want to win a $50 Amazon Gift Card??</h4>
              <p className="mb-0 text-red">
                {' '}
                  January 22 thru February 3. Create a post on <strong>Easyfieds</strong> and you will be entered to win! Use <strong>Easyfieds</strong> to post
                  rabbits, cavies, rabbit/cavy related items for sale or judges/registrars post here so clubs can find you. Don’t have a
                  rabbit, cavy or related item for sale? Use <strong>Easyfieds</strong> like a breeder listing to show potential customers what breeds
                  you do raise and what you might have in the future. Only people with an <strong>Easy2show</strong> account can view <strong>Easyfieds</strong>,
                  so you don’t have to worry about being put in social media “jail” for your sale post. Login into your Easy2show
                  account to view the <strong>Easyfieds</strong> tab. We will email the winner who will need to provide an address for us to mail the
                  Amazon gift card to. A Signature is required for delivery. The winner will be notified by 2-10-24. Commercial
                  vendors are not eligible for gift card.
              </p>
            </Col>
          </CardHeader>
        </Card> */}
        <Col lg="12" className="text-center mb-3 filter-line">
          <p>
            Filters will only show drop down with options that have data listed.
          </p>
        </Col>

        <Card>
          <CardHeader>
            <Row className="mt-5">
              <Col md="3" sm="6" xs={'12'} className="mb-4">
                <Input
                  id="exampleSelect"
                  className="form-select frm-sel-pad"
                  name="select"
                  onChange={(e) => {
                    setBreed_id('');
                    setClass_type('');
                    dispatch(pageChange(1));
                    setCategory_id(e.target.value);
                    setState_id('');
                    setCity('');
                  }}
                  type="select"
                  value={category_id}
                >
                  <option value={''}>Select Category</option>
                  {Array.isArray(BreedCategory) && BreedCategory.length
                    ? BreedCategory.map((category, i) => {
                        return (
                          <option value={category?.breed_category_id} key={i}>
                            {category?.category_name}
                          </option>
                        );
                      })
                    : null}
                </Input>
              </Col>
              <Col md="3" sm="6" xs={'12'} className="mb-4">
                <select
                  name="breed"
                  className="form-control frm-sel-pad"
                  onChange={(e) => {
                    dispatch(pageChange(1));
                    setClass_type('');
                    setBreed_id(e.target.value);
                    setState_id('');
                    setCity('');
                  }}
                  value={breed_id}
                >
                  <option value="">Select Breed</option>
                  {Array.isArray(finalBreedList) && finalBreedList.length
                    ? finalBreedList.map((_breed, i) => {
                        return (
                          <option value={_breed?.breed_id || ''} key={i}>
                            {_breed?.breed_name || ''}
                          </option>
                        );
                      })
                    : null}
                </select>
              </Col>
              <Col md="2" sm="6" xs={'12'} className="mb-4">
                <Input
                  id="exampleSelect"
                  className="form-select frm-sel-pad"
                  name="select"
                  type="select"
                  value={class_type}
                  onChange={(e) => {
                    dispatch(pageChange(1));

                    setClass_type(e.target.value);
                    setState_id('');
                    setCity('');
                  }}
                >
                  <option value={''}>Select Class Type</option>
                  {Array.isArray(finalClassList) && finalClassList.length
                    ? finalClassList.map((classes, i) => {
                        return (
                          <option value={classes?.class_id || ''} key={i}>
                            {' '}
                            {classes?.class_name || ''}
                          </option>
                        );
                      })
                    : null}
                </Input>
              </Col>
              <Col md="2" sm="6" xs={'12'} className="mb-4">
                <Input
                  id="exampleSelect"
                  className="form-select frm-sel-pad"
                  name="select"
                  type="select"
                  value={state_id}
                  onChange={(e) => {
                    dispatch(pageChange(1));
                    setState_id(e.target.value);
                    setCity('');
                  }}
                >
                  <option value={''}>Select State</option>
                  {Array.isArray(StatesList) && StatesList.length
                    ? StatesList.map((state, i) => {
                        return (
                          <option value={state?.state_id || ''} key={i}>
                            {' '}
                            {state?.state_code || ''}
                          </option>
                        );
                      })
                    : null}
                </Input>
              </Col>
              <Col md="2" sm="6" xs={'12'} className="mb-4">
                <Input
                  id="exampleSelect"
                  className="form-select"
                  name="select"
                  type="select"
                  value={city}
                  onChange={(e) => {
                    dispatch(pageChange(1));
                    setCity(e.target.value);
                  }}
                >
                  <option value={''}>Select City</option>
                  {Array.isArray(cityList) && cityList.length
                    ? cityList.map((city, i) => {
                        return (
                          <option value={city?.city_name || ''} key={i}>
                            {' '}
                            {city?.city_name || ''}
                          </option>
                        );
                      })
                    : null}
                </Input>
              </Col>
              {/* <div className='col-md-12 mt-3 text-center'>
                <h5 className='text-danger'>
                  {' '}
                  Note:If the breed has been unpublished, and there are entries
                  associated with it, 'Breed Deleted' will be displayed in the
                  breed field.
                </h5>
              </div>
              <div className='col-md-12 mt-3 text-center'>
                <h5 className='text-danger'>
                  {' '}
                  Note:If the class has been deleted, and there are entries
                  associated with it, 'class Deleted' will be displayed under
                  the class field.
                </h5>
              </div> */}
            </Row>
          </CardHeader>
          <CardBody>
            <div className="col-md-12">
              <Table responsive hover1 className="table cust-tab table">
                <thead className="table-color">
                  <tr>
                    <th>No.</th>
                    <th>Breed</th>
                    <th>Image</th>
                    <th>Amount </th>
                    <th>City </th>
                    <th>State </th>
                    <th>Action </th>
                    <th>More Info </th>
                  </tr>
                </thead>
                <tbody>
                  {!loading &&
                  Array.isArray(MyAnimalList) &&
                  MyAnimalList.length ? (
                    MyAnimalList.map((animal, index) => {
                      return (
                        <>
                          <tr>
                            <td>{(page - 1) * perPage + (index + 1)}</td>
                            <td>{<BreedName ID={animal?.breed_id} />}</td>
                            <td>
                              <AnimalImage
                                ID={animal?.animal_listing_id}
                                showModal={showModal}
                              />
                            </td>
                            <td>${animal?.amount || ''}</td>
                            <td>{animal?.city || ''}</td>
                            <td>
                              {' '}
                              <StateName ID={animal?.state_id} />
                            </td>
                            <td>
                              <Link
                                to={`/easyfides/contact/${urlEncode(
                                  animal?.animal_listing_id
                                )}`}
                              >
                                <Button
                                  size="sm"
                                  color="secondary"
                                  title="Contact"
                                >
                                  CONTACT
                                </Button>
                              </Link>
                            </td>

                            <td>
                              <i
                                class="fa fa-arrow-circle-down down-arrow"
                                onClick={() =>
                                  handleClick(animal?.animal_listing_id)
                                }
                              ></i>
                            </td>
                          </tr>
                          {animal?.animal_listing_id === open ? (
                            <tr>
                              <td colSpan={9}>
                                <Collapse
                                  in={
                                    animal?.animal_listing_id === open
                                      ? true
                                      : false
                                  }
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  <div className="disc-box text-left w-break">
                                    {animal?.description || ''}
                                  </div>
                                </Collapse>
                              </td>
                            </tr>
                          ) : null}
                        </>
                      );
                    })
                  ) : loading ? (
                    <tr className="text-center">
                      <td colSpan={10}>
                        <Spinner color="primary" />
                      </td>
                    </tr>
                  ) : (
                    <tr className="text-center">
                      <td colSpan={10}>No rabbits & cavies found</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
            <Row>
              <Col lg={12} className="easy-page">
                <Pagination paginationClick={paginationClick} />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <ImageModal
          modalClose={modalClose}
          modal={modal}
          imageList={imageList}
        />
        {/* <Modal
          isOpen={modal}
          // toggle={() => {
          //   setModal(false)
          //   setImageList([])
          // }}
          className='bg-white'
          // size='lg'
        >
          <ModalHeader
          // toggle={() => {
          //   setModal(false)
          //   setImageList([])
          // }}
          >
            Images
          </ModalHeader>
          <ModalBody>
            {Array.isArray(imageList) && imageList.length ? (
              <ImageGallery items={imageList} />
            ) : null}
          </ModalBody>
          <ModalFooter>
            <Button
              color='warning'
              onClick={() => {
                setModal(false)
                setImageList([])
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal> */}
      </Container>
    </section>
  );
};

export default AnimalListing;
const BreedName = ({ ID }) => {
  const [breedData, setbreedData] = useState({});
  useEffect(() => {
    get(`/api/v1/breed-setup/class/one/${ID}`).then((res) => {
      setbreedData(_.first(res.data));
    });
  }, [ID]);

  return breedData?.is_published ? (
    breedData?.breed_name || '-'
  ) : (
    <span className="text-danger">Breed Deleted</span>
  );
  // return null
};
const AnimalImage = ({ ID, showModal }) => {
  const [breedData, setbreedData] = useState({});

  useEffect(() => {
    get(
      `/api/v1/easyfied/animal-listing-doc/user/all?animal_listing_id=${ID}`
    ).then((res) => {
      setbreedData(_.first(res.data));
    });
  }, [ID]);

  return breedData?.document_file ? (
    <div onClick={() => showModal(ID)}>
      <img
        src={breedData?.document_file}
        alt={breedData?.image_title || ''}
        style={{ width: '100px' }}
        className="img-thumbnail rounded"
        title={breedData?.image_title || ''}
      />
    </div>
  ) : (
    <img
      src={Logo}
      alt="logo"
      style={{ width: '100px' }}
      className="img-thumbnail rounded"
      title={'logo'}
    />
  );
};
const StateName = ({ ID }) => {
  const [stateData, setStateData] = useState('');

  useEffect(() => {
    get(`/api/v1/address/state/one/${ID}`).then((res) => {
      setStateData(res?.data);
    });
  }, [ID]);

  return stateData?.state_name || '-';
};
// const CountryName = ({ ID }) => {
//   const [countryData, setCountryData] = useState('')

//   useEffect(() => {
//     get(`/api/v1/address/country/one${ID}`).then((res) => {
//       setCountryData(res?.data?.data)
//     })
//   }, [ID])

//   return countryData?.country_name || '-'
// }

const Category = [
  {
    breed_category_id: 1,
    category_id: 1,
    category_name: 'Rabbit Breed List',
  },
  {
    breed_category_id: 2,
    category_id: 2,
    category_name: 'Cavy Breed List',
  },
  {
    breed_category_id: 3,
    category_id: 3,
    category_name: 'Exhibition Breed List',
  },
  {
    breed_category_id: 4,
    category_id: 4,
    category_name: 'Commercial Breed List',
  },
];
