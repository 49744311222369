import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'

// Login Redux States
import {
  AUTH_FAIL,
  LOAD_USER,
  LOAD_USER_SUCCESS,
  LOGIN_USER,
  LOGOUT_USER,
  PREMIER_USER_DETAIL,
  SOCIAL_LOGIN,
} from './actionTypes'
import {
  apiError,
  loginSuccess,
  logoutUserSuccess,
  premierUsersSuccess,
} from './actions'

//Include Both Helper File with needed methods
import { getFirebaseBackend } from '../../../helpers/firebase_helper'
import {
  postFakeLogin,
  postJwtLogin,
  postSocialLogin,
} from '../../../helpers/fakebackend_helper'
import { toast } from 'react-toastify'
import { post, get } from '../../../helpers/api_helper'

const fireBaseBackend = getFirebaseBackend()

function* loginUser({ payload: { user, history, redirect } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
      const response = yield call(
        fireBaseBackend.loginUser,
        user.email,
        user.password
      )
      yield put(loginSuccess(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === 'jwt') {
      const response = yield call(postJwtLogin, {
        email: user.email,
        password: user.password,
      })
      localStorage.setItem('authUser', JSON.stringify(response))
      yield put(loginSuccess(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === 'fake') {
      const response = yield call(postFakeLogin, {
        email: user.email,
        password: user.password,
      })
      localStorage.setItem('authUser', JSON.stringify(response))
      yield put(loginSuccess(response))
    }
    if (redirect !== 0) {
      history.push('/dashboard')
    }
  } catch (error) {
    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem('authUser')
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')

    if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
      const response = yield call(fireBaseBackend.logout)
      yield put(logoutUserSuccess(response))
    }
    history.push('/events')
  } catch (error) {
    yield put(apiError(error))
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
      const fireBaseBackend = getFirebaseBackend()
      const response = yield call(fireBaseBackend.socialLoginUser, data, type)
      localStorage.setItem('authUser', JSON.stringify(response))
      yield put(loginSuccess(response))
    } else {
      const response = yield call(postSocialLogin, data)
      localStorage.setItem('authUser', JSON.stringify(response))
      yield put(loginSuccess(response))
    }
    history.push('/dashboard')
  } catch (error) {
    yield put(apiError(error))
  }
}

function* premierUser({ payload }) {
  try {
    const res = yield post(
      `/api/v1/premier-exhibitor/purchased-plans/premier-exh`,
      payload.data
    )
    yield put(premierUsersSuccess(res?.data))
    if (payload.callback) payload.callback(null, res?.data)
  } catch (err) {
    if (err?.response?.data) {
      toast.error(err?.response?.data)
    }
  }
}

function* loadUserDetail({ payload }) {
  try {
    const res = yield get(`/api/v1/profile/profile_details`)
    if (res) {
      yield put({
        type: LOAD_USER_SUCCESS,
        payload: res,
      })
    }
  } catch (err) {
    
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeLatest(SOCIAL_LOGIN, socialLogin)
  yield takeEvery(LOGOUT_USER, logoutUser)
  yield takeEvery(PREMIER_USER_DETAIL, premierUser)
  yield takeEvery(LOAD_USER, loadUserDetail)
}

export default authSaga
