import React from 'react'
import Footer from './Footer'
import Header from './Header'

const EasyFiedsLayout = (props) => {
  // window.scrollTo(0, 0)
  return (
    <React.Fragment>
      <div id='layout-wrapper'>
        <header id='page-topbar'>
          <Header />
        </header>
        <div className='main-content'>{props.children}</div>
        <Footer  />
      </div>
    </React.Fragment>
  )
}

export default EasyFiedsLayout
